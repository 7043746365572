/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DropDownList from '../../components/DropDownList';
import TextBox from '../../components/TextBox';
import normalizeNumber from '../IraAccount/normalizeNumber';

const EmploymentFormSection = (props) => {
  const {
    isJointApplication,
    sectionIndex,
    employmentStatusOptions,
    industryOptions,
    jobTitleOptions,
    countries,
    regions,
    showEmploymentForm,
    showIsPublic,
    showIsBrokerage,
    showCrdNumber,
    handleOnChangeIndustry,
  } = props;
  const isExistingCustomer = props.registration ? props.registration.isExistingCustomer : false;
  const isPrimary = sectionIndex === 0;
  const gridClass = isJointApplication ? 'col col-md-6 col-xs-12' : 'col col-md-12 col-xs-12';
  const rbIsBrokerageTrue = `employments[${sectionIndex}].isBrokerageTrue`;
  const rbIsBrokerageFalse = `employments[${sectionIndex}].isBrokerageFalse`;
  const rbIsPublicTrue = `employments[${sectionIndex}].isPublicTrue`;
  const rbIsPublicFalse = `employments[${sectionIndex}].isPublicFalse`;
  const rbIsMarketDataForBusinessTrue = `employments[${sectionIndex}].rbIsMarketDataForBusinessTrue`;
  const rbIsMarketDataForBusinessFalse = `employments[${sectionIndex}].rbIsMarketDataForBusinessFalse`;
  const rbIsRegisteredWithSecuritiesTrue = `employments[${sectionIndex}].rbIsRegisteredWithSecuritiesTrue`;
  const rbIsRegisteredWithSecuritiesFalse = `employments[${sectionIndex}].rbIsRegisteredWithSecuritiesFalse`;
  const rbIsInvestmentAdvisorTrue = `employments[${sectionIndex}].rbIsInvestmentAdvisorTrue`;
  const rbIsInvestmentAdvisorFalse = `employments[${sectionIndex}].rbIsInvestmentAdvisorFalse`;

  return (
    <div>
      <div className={gridClass}>
        <div className="row">
          <div className="col col-md-12">
            <h1 className="h2_subHeading ">Employer <span className="blue-text">
              {isPrimary ? '' : '(Joint)'}</span></h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            <DropDownList
              fieldName="employmentStatus"
              className="form-control"
              label="Employment Status"
              options={employmentStatusOptions}
            />
          </div>
        </div>
        {showEmploymentForm[sectionIndex] &&
          <div className="row">
            <div className={isJointApplication ? 'col col-md-12' : 'col col-md-6'}>
              <DropDownList
                fieldName="industry"
                className="form-control"
                label="Industry"
                options={industryOptions}
                handleChange={event => handleOnChangeIndustry(event)}
              />
              <DropDownList
                fieldName="title"
                className="form-control"
                label="Job Title"
                options={jobTitleOptions[sectionIndex]}
              />
              <TextBox
                name="employmentName"
                className="form-control"
                label="Company Name"
                maxLength={40}
              />
            </div>
            <div className={isJointApplication ? 'col col-md-12' : 'col col-md-6'}>
              <DropDownList
                fieldName="employmentCountry"
                className="form-control"
                label="Country"
                options={countries}
              />
              <TextBox
                name="employmentAddress1"
                className="form-control"
                label="Employment address 1"
                maxLength={40}
                allowspecialcharacters
              />
              <TextBox
                name="employmentAddress2"
                className="form-control"
                label="Employment address 2"
                maxLength={40}
                allowspecialcharacters
              />
              <TextBox
                name="employmentCity"
                className="form-control"
                label="City"
                maxLength={30}
                allowspecialcharacters
              />
              <div className="row">
                <div className={isJointApplication ? 'col col-md-12' : 'col col-md-5'}>
                  <TextBox
                    name="employmentZip"
                    className="form-control"
                    label="Zip Code"
                    maxLength={10}
                  />
                </div>
                <div className={isJointApplication ? 'col col-md-12' : 'col col-md-7'}>
                  {regions[sectionIndex] && regions[sectionIndex].length > 1 &&
                    <DropDownList
                      fieldName="employmentRegion"
                      className="form-control"
                      label="State or Province"
                      options={regions[sectionIndex]}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <div className="row">
          <div className="col col-md-12">
            <h1 className="h2_subHeading ">
              Affiliations <span className="blue-text">{isPrimary ? '' : '(Joint)'}</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            <div className="panel panel-clear">
              <div className="panel-body">
                <p className="panel-question">
                  Are you or your spouse associated with or employed by a NYSE, FINRA,
                  and/or NFA registered brokerage firm?
                </p>
                <div className="row">
                  <div className="col col-xs-12">
                    <div className="form-group form-group-inputs">
                      <div className="radio">
                        <label htmlFor={rbIsBrokerageTrue}>
                          <Field
                            name="isBrokerage"
                            id={rbIsBrokerageTrue}
                            component="input"
                            type="radio"
                            value="true"
                          />
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xs-12">
                    <div className="form-group form-group-inputs">
                      <div className="radio">
                        <label htmlFor={rbIsBrokerageFalse}>
                          <Field
                            name="isBrokerage"
                            id={rbIsBrokerageFalse}
                            component="input"
                            type="radio"
                            value="false"
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {showIsBrokerage[sectionIndex] &&
                  <div className="row">
                    <div className={isJointApplication ? 'col col-md-12' : 'col col-xs-12 col-md-6'}>
                      <TextBox
                        name="brokerageCompanyName"
                        className="form-control"
                        label="Please enter the firm name"
                        maxLength={40}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="panel panel-clear">
              <div className="panel-body">
                <p className="panel-question">
                  Are you a director, 10% shareholder, or policy-making officer of a publicly-owned company?
                </p>
                <div className="row">
                  <div className="col col-xs-12">
                    <div className="form-group form-group-inputs">
                      <div className="radio">
                        <label htmlFor={rbIsPublicTrue}>
                          <Field
                            name="isPublic"
                            id={rbIsPublicTrue}
                            component="input"
                            type="radio"
                            value="true"
                          />
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xs-12">
                    <div className="form-group form-group-inputs">
                      <div className="radio">
                        <label htmlFor={rbIsPublicFalse}>
                          <Field
                            name="isPublic"
                            id={rbIsPublicFalse}
                            component="input"
                            type="radio"
                            value="false"
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {showIsPublic[sectionIndex] &&
                  <div className="row">
                    <div className={isJointApplication ? 'col col-md-12' : 'col col-xs-12 col-md-6'}>
                      <TextBox
                        name="publicSymbol"
                        className="form-control form-group-inputs"
                        label="Please indicate the trading symbol"
                        maxLength={10}
                      />
                    </div>
                    <div className={isJointApplication ? 'col col-md-12' : 'col col-xs-12 col-md-6'}>
                      <TextBox
                        name="publicCompanyName"
                        className="form-control form-group-inputs"
                        label="Please enter the name of the company"
                        maxLength={40}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        {!isExistingCustomer &&
          <div className="non-pro-questions">
            <div className="row">
              <div className="col col-md-12">
                <div className="panel panel-clear">
                  <div className="panel-body">
                    <p className="panel-question">
                      Do you use market data for your business or any other entity, instead of your own personal investments?
                      This includes if you advise, teach, or sell investment or trading strategies commercially.
                    </p>
                    <div className="row">
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsMarketDataForBusinessTrue}>
                              <Field
                                name="isMarketDataForBusiness"
                                id={rbIsMarketDataForBusinessTrue}
                                component="input"
                                type="radio"
                                value="true"
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsMarketDataForBusinessFalse}>
                              <Field
                                name="isMarketDataForBusiness"
                                id={rbIsMarketDataForBusinessFalse}
                                component="input"
                                type="radio"
                                value="false"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12">
                <div className="panel panel-clear">
                  <div className="panel-body">
                    <p className="panel-question">
                      Are you currently registered or qualified with any securities agency, any securities exchange, association or regulatory body,
                      or any commodities or futures contract market,association or regulatory body, in the United States or elsewhere? For example,
                      SEC (Securities Exchange) or the CFTC.
                    </p>
                    <div className="row">
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsRegisteredWithSecuritiesTrue}>
                              <Field
                                name="isRegisteredWithSecurities"
                                id={rbIsRegisteredWithSecuritiesTrue}
                                component="input"
                                type="radio"
                                value="true"
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsRegisteredWithSecuritiesFalse}>
                              <Field
                                name="isRegisteredWithSecurities"
                                id={rbIsRegisteredWithSecuritiesFalse}
                                component="input"
                                type="radio"
                                value="false"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showCrdNumber[sectionIndex] &&
                      <div className="row">
                        <div className={isJointApplication ? 'col col-md-12' : 'col col-xs-12 col-md-6'}>
                          <TextBox
                            name="crdNumber"
                            className="form-control"
                            label="Please provide CRD Number, if available."
                            maxLength={10}
                            normalize={normalizeNumber}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12">
                <div className="panel panel-clear">
                  <div className="panel-body">
                    <p className="panel-question">
                      Are you engaged as an &quot;investment advisor&quot;, providing advice and/or using capital of any other individual or
                      entity in the conduct of your trading? This would include your sharing in the profit, using capital of any other individual or
                      receiving compensation of trading activity.
                    </p>
                    <div className="row">
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsInvestmentAdvisorTrue}>
                              <Field
                                name="isInvestmentAdvisor"
                                id={rbIsInvestmentAdvisorTrue}
                                component="input"
                                type="radio"
                                value="true"
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col col-xs-12">
                        <div className="form-group form-group-inputs">
                          <div className="radio">
                            <label htmlFor={rbIsInvestmentAdvisorFalse}>
                              <Field
                                name="isInvestmentAdvisor"
                                id={rbIsInvestmentAdvisorFalse}
                                component="input"
                                type="radio"
                                value="false"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

EmploymentFormSection.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  employmentStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  industryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  jobTitleOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))).isRequired,
  showEmploymentForm: PropTypes.arrayOf(PropTypes.bool).isRequired,
  showIsPublic: PropTypes.arrayOf(PropTypes.bool).isRequired,
  showIsBrokerage: PropTypes.arrayOf(PropTypes.bool).isRequired,
  showCrdNumber: PropTypes.arrayOf(PropTypes.bool),
  registration: PropTypes.shape(),
  handleOnChangeIndustry: PropTypes.func.isRequired,
};

export default EmploymentFormSection;
