import * as employmentTypes from '../components/Employment/employmentTypes';
import { domesticZipCodePattern } from '../lib/constants';

export const validate = (values) => {
  const errors = {};

  if (values.employments && values.employments.length > 0) {
    const employmentsArrayErrors = [];
    values.employments.forEach((employment, index) => {
      const employmentErrors = {};
      if (!employment.employmentStatus) {
        employmentErrors.employmentStatus = 'Required';
        employmentsArrayErrors[index] = employmentErrors;
      }

      if (employment.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_EMPLOYED
        || employment.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
        if (!employment.industry) {
          employmentErrors.industry = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.title) {
          employmentErrors.title = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentName) {
          employmentErrors.employmentName = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentName) {
          employmentErrors.employmentName = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.employmentName.length > 40 || employment.employmentName.length < 3) {
          employmentErrors.employmentName = 'Minimum length is 3, maximum 40';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentAddress1) {
          employmentErrors.employmentAddress1 = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.employmentAddress1.length > 40 || employment.employmentAddress1.length < 5) {
          employmentErrors.employmentAddress1 = 'Minimum length is 5, maximum 40';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (employment.employmentAddress2) {
          if (employment.employmentAddress2.length > 40) {
            employmentErrors.employmentAddress2 = 'Maximum length is 40';
            employmentsArrayErrors[index] = employmentErrors;
          }
        }

        if (!employment.employmentCountry) {
          employmentErrors.employmentCountry = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentCity) {
          employmentErrors.employmentCity = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.employmentCity.length > 30 || employment.employmentCity.length < 2) {
          employmentErrors.employmentCity = 'Minimum length is 2, maximum 30';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentRegion) {
          employmentErrors.employmentRegion = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.employmentZip) {
          if (employment.employmentCountry === 'US') {
            employmentErrors.employmentZip = 'Required';
            employmentsArrayErrors[index] = employmentErrors;
          }
        } else if (employment.employmentCountry === 'US' && !domesticZipCodePattern.test(employment.employmentZip)) {
          employmentErrors.employmentZip = 'Only the formats 12345, 12345-1234, 123456789 are valid.';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.employmentZip.length > 10 || employment.employmentZip.length < 2) {
          employmentErrors.employmentZip = 'Minimum length is 2, maximum 10';
          employmentsArrayErrors[index] = employmentErrors;
        }
      }

      if (employment.isBrokerage === 'true') {
        if (!employment.brokerageCompanyName) {
          employmentErrors.brokerageCompanyName = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.brokerageCompanyName.length > 40 || employment.brokerageCompanyName.length < 5) {
          employmentErrors.brokerageCompanyName = 'Minimum length is 5, maximum 40';
          employmentsArrayErrors[index] = employmentErrors;
        }
      }

      if (employment.isPublic === 'true') {
        if (!employment.publicSymbol) {
          employmentErrors.publicSymbol = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.publicSymbol.length > 10 || employment.publicSymbol.length < 2) {
          employmentErrors.publicSymbol = 'Minimum length is 2, maximum 10';
          employmentsArrayErrors[index] = employmentErrors;
        }

        if (!employment.publicCompanyName) {
          employmentErrors.publicCompanyName = 'Required';
          employmentsArrayErrors[index] = employmentErrors;
        } else if (employment.publicCompanyName.length > 40 || employment.publicCompanyName.length < 5) {
          employmentErrors.publicCompanyName = 'Minimum length is 5, maximum 40';
          employmentsArrayErrors[index] = employmentErrors;
        }
      }

      if (employment.isRegisteredWithSecurities === 'true') {
        if (employment.crdNumber && employment.crdNumber.length > 10) {
          employmentErrors.crdNumber = 'Maximum length is 10';
          employmentsArrayErrors[index] = employmentErrors;
        }
      }
    });

    if (employmentsArrayErrors.length > 0) {
      errors.employments = employmentsArrayErrors;
    }
  }

  return errors;
};

export default validate;
