import React from 'react';
import PropTypes from 'prop-types';
import ContactHeading from '../Contact/ContactHeading';
import Navigation from '../Navigation';
import SummaryBasicInfoContainer from '../../containers/Summary/SummaryBasicInfoContainer';
import SummaryCustomerProfileContainer from '../../containers/Summary/SummaryCustomerProfileContainer';
import SummaryPersonalInfoContainer from '../../containers/Summary/SummaryPersonalInfoContainer';
import SummaryIRAAccountContainer from '../../containers/Summary/SummaryIRAAccountContainer';
import SummaryEmploymentContainer from '../../containers/Summary/SummaryEmploymentContainer';
import SummaryFinancialContainer from '../../containers/Summary/SummaryFinancialContainer';
import SummaryTradingAuthContainer from '../../containers/Summary/SummaryTradingAuthContainer';
import SummaryDocumentsContainer from '../../containers/Summary/SummaryDocumentsContainer';
import SummaryAccountTypeAssetContainer from '../../containers/Summary/SummaryAccountTypeAssetContainer';

const SummaryInformation = (props) => {
  const {
    isJointApplication,
    isIRAApplication,
    haveErrors,
    handleNext,
    handleBack,
  } = props;

  return (
    <div>
      <div className="side-padding">
        {isJointApplication &&
          <div className=" summary-contact-heading">
            <ContactHeading sectionIndex={0} />
          </div>
        }
        <div
          className="panel-group panel-group-summary accordion"
          id="accordion-0"
          role="tablist"
          aria-multiselectable="true"
        >
          <div>
            <SummaryCustomerProfileContainer {...props} groupIndex={0} sectionIndex={1} />
          </div>
          <SummaryAccountTypeAssetContainer {...props} groupIndex={0} sectionIndex={4} />
          <SummaryPersonalInfoContainer {...props} groupIndex={0} sectionIndex={6} />
          <SummaryEmploymentContainer {...props} groupIndex={0} sectionIndex={7} />
          <SummaryFinancialContainer {...props} groupIndex={0} sectionIndex={8} />
          {isIRAApplication &&
            <SummaryIRAAccountContainer {...props} groupIndex={0} sectionIndex={9} />
          }
          <SummaryTradingAuthContainer {...props} groupIndex={0} sectionIndex={10} />
          <SummaryDocumentsContainer {...props} groupIndex={0} sectionIndex={11} />
        </div>
        {isJointApplication &&
          <div>
            <div className=" summary-contact-heading">
              <ContactHeading sectionIndex={1} />
            </div>
            <div
              className="panel-group panel-group-summary accordion"
              id="accordion-1"
              role="tablist"
              aria-multiselectable="true"
            >
              <SummaryBasicInfoContainer {...props} groupIndex={1} sectionIndex={1} />
              <SummaryPersonalInfoContainer {...props} groupIndex={1} sectionIndex={6} />
              <SummaryEmploymentContainer {...props} groupIndex={1} sectionIndex={7} />
              <SummaryFinancialContainer {...props} groupIndex={1} sectionIndex={8} />
              <SummaryDocumentsContainer {...props} groupIndex={1} sectionIndex={11} />
            </div>
          </div>
        }
      </div>
      <div className="col col-xs-12">
        <Navigation onBack={handleBack} onNext={handleNext} showSaveForLater={false} haveErrors={haveErrors} />
      </div>
    </div>
  );
};


SummaryInformation.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  isIRAApplication: PropTypes.bool.isRequired,
  contactName: PropTypes.arrayOf(PropTypes.string).isRequired,
  haveErrors: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default SummaryInformation;
