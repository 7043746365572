import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as applicationActions from '../actions/applicationActions';
import * as registrationActions from '../actions/registrationActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as financialActions from '../actions/financialActions';
import DepositFunds from '../components/DepositFunds/DepositFunds';
import Funding from '../components/DepositFunds/Funding';
import Config from '../Config';
import * as RouteNavigator from './RouteNavigator';
import * as userActions from '../actions/userActions';
import optimizeHelper from '../lib/optimizeHelper';
import signOutUrl from '../lib/signOutUrl';
import hasAccountsOrFake from '../lib/containerHelpers/approvalStatus/approvalStatusHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { deleteCookieDomain, deleteCookie } from '../lib/cookieManager';

const config = new Config();
let modalTimer;

const redirectToAccountType = () => {
  RouteNavigator.push('/account-type');
};

const redirectToClientCenter = () => {
  window.location = config.clientCenterUrl;
};

const redirectToTsLearn = () => {
  window.location = config.tradestationLearnUrl;
};

const redirectToMidland = () => {
  window.open('https://midlandtrust.com/landing/tradestation', '_blank');
};

const toClientCenterListener = (event) => {
  const { data } = event;
  if (data.logout) {
    redirectToClientCenter();
  }
};

export class DepositFundsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      shouldShowFunding: false,
      shouldInlineFunding: false,
      showLeavingTradeStationModal: false,
      modalTimeLeft: 10,
    };
    this.createFundingUrl = this.createFundingUrl.bind(this);
    this.showFunding = this.showFunding.bind(this);
    this.showInlineFunding = this.showInlineFunding.bind(this);
    this.startNewApplication = this.startNewApplication.bind(this);
    this.checkInlineFunding = this.checkInlineFunding.bind(this);
    this.finishApplication = this.finishApplication.bind(this);
    this.toggleShowLeavingTradeStationModal = this.toggleShowLeavingTradeStationModal.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.endTimerAndModal = this.endTimerAndModal.bind(this);
    this.handleShowLeavingTradeStationModal = this.handleShowLeavingTradeStationModal.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      application,
      applicationId,
      authToken,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
      financial,
    } = this.props;


    if (!application) actions.fetchApplication(applicationId, authToken);
    if (!registration) actions.fetchRegistration(applicationId, authToken);
    if (
      !registration ||
      !accountType ||
      !assetClass ||
      !identity ||
      !address ||
      !tradingProfile ||
      !financial
    ) {
      deleteCookie('pendingApplication');
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
        actions.fetchFinancial(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(aopAnalyticsSteps.THANK_YOU.name, {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
          financial,
        });
      });
    } else {
      pushToAnalytics(aopAnalyticsSteps.THANK_YOU.name, {
        applicationId,
        authToken,
        registration,
        accountType,
        assetClass,
        address,
        identity,
        tradingProfile,
        financial,
      });
    }

    window.addEventListener('message', toClientCenterListener, false);
    optimizeHelper.notify();
  }

  componentWillUnmount() {
    window.removeEventListener('message', toClientCenterListener, false);
  }

  createFundingUrl() {
    const { fundingUrl } = config;
    const {
      authToken,
      registration: { username },
    } = this.props;
    return `${fundingUrl}/?client_id=aop&access_token=${authToken}&user_id=${username}`;
  }

  showFunding() {
    this.setState({ shouldShowFunding: true });
  }

  showInlineFunding() {
    this.setState({ shouldInlineFunding: true });
  }

  startNewApplication() {
    const { authToken, userId } = this.props;
    this.props.actions
      .startApplication(userId, authToken)
      .then(redirectToAccountType)
      .catch(() => {
        this.props.actions.userLogout(this.props.authToken).then(() => {
          window.location = signOutUrl('/logout.html?manual');
        });
      });
  }

  finishApplication() {
    this.props.actions.userLogout(this.props.authToken).then(redirectToTsLearn);
  }

  toggleShowLeavingTradeStationModal() {
    this.setState({ showLeavingTradeStationModal: !this.state.showLeavingTradeStationModal });
  }

  endTimerAndModal(redirect = false) {
    if (redirect) redirectToMidland();
    clearInterval(modalTimer);
    this.toggleShowLeavingTradeStationModal();
  }

  startTimer() {
    this.setState({ modalTimeLeft: 10 });
    modalTimer = setInterval(() => {
      if (this.state.modalTimeLeft <= 0) {
        this.endTimerAndModal(true);
      } else {
        this.setState({ modalTimeLeft: this.state.modalTimeLeft - 1 });
      }
    }, 1000);
  }

  handleShowLeavingTradeStationModal() {
    this.toggleShowLeavingTradeStationModal();
    this.startTimer();
  }

  shouldShowDepositFundsAndFunding() {
    // const { application, registration } = this.props;
    const { application } = this.props;
    const { hasCarmaAccounts, hasFakeOrNoMoreBusiness, hasBypassedCarmaAccounts } = hasAccountsOrFake(application);

    if (hasCarmaAccounts && !hasFakeOrNoMoreBusiness && !hasBypassedCarmaAccounts) {
      deleteCookieDomain('ts_incomplete_app', 'tradestation.com');
    }

    return (
      hasCarmaAccounts && !hasFakeOrNoMoreBusiness && !hasBypassedCarmaAccounts
    );
  }

  checkInlineFunding() {
    const { shouldInlineFunding } = this.state;
    if ((config.inlineFunding === 'V2' || config.inlineFunding === 'V3') && shouldInlineFunding) {
      RouteNavigator.push('/funding');
    }
      return (<Funding
        {...this.props}
        fundingUrl={this.createFundingUrl()}
      />);
  }

  render() {
    const { shouldShowFunding, shouldInlineFunding } = this.state;
    return this.shouldShowDepositFundsAndFunding() && ((shouldShowFunding || shouldInlineFunding) ?
      this.checkInlineFunding()
      :
      (<DepositFunds
        {...this.props}
        showFunding={this.showFunding}
        showInlineFunding={this.showInlineFunding}
        accountsCreated={this.props.application.accountsCreated}
        startNewApplication={this.startNewApplication}
        finishApplication={this.finishApplication}
        handleShowLeavingTradeStationModal={this.handleShowLeavingTradeStationModal}
        showLeavingTradeStationModal={this.state.showLeavingTradeStationModal}
        modalTimeLeft={this.state.modalTimeLeft}
        endTimerAndModal={this.endTimerAndModal}
      />)
    );
  }
}

DepositFundsContainer.propTypes = {
  authToken: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  application: PropTypes.shape(),
  registration: PropTypes.shape(),
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    startApplication: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchFinancial: PropTypes.func.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  accountType: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  financial: PropTypes.shape(),
};

function mapStateToProps(state) {
  return {
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    registration: state.registration,
    userId: state.userId,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      applicationActions,
      registrationActions,
      userActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      addressActions,
      tradingProfileActions,
      financialActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositFundsContainer);
