import React from 'react';
import PropTypes from 'prop-types';


import { Field } from 'redux-form';

const AssetType = ({
  assetType,
  id,
  linkUrl,
  text,
  name,
  canTradeBoth,
  disabled,
  handleOnChangeAssetType,
  modalTarget,
  limitText,
}) => {
  const LINK_CLASS_NAME =
    `btn btn-img checkbox btn-img-auto form-group col-xs-12 LINKCLASSNAME ${disabled ? 'disabled' : ''}`;

  const LINK_CLASS_NAME_SINGLE =
    `btn btn-img checkbox btn-img-auto form-group col-xs-12 LINKCLASSNAME ${disabled ? 'disabled' : ''}`;

  return (
    <a href="#" className={!canTradeBoth ? `${LINK_CLASS_NAME_SINGLE}  btn-img-float-none}` : LINK_CLASS_NAME}>
      <label className="row" htmlFor={id}>
        <Field
          data-target={modalTarget}
          data-toggle="modal"
          name={name}
          id={id}
          component="input"
          type="checkbox"
          value={assetType}
          onClick={() => handleOnChangeAssetType(name)}
          disabled={disabled ? 'disabled' : ''}
        />
        {limitText ? (
          <div className="col col-md-12">
            <div className="col col-md-7 col-xs-7">
              <div className="btn-label">
                <p>{text}</p>
              </div>
            </div>
            <div className="col col-md-5 col-xs-5">
              <div className="btn-label btn-label-right">
                {limitText}
              </div>
            </div>
          </div>
        ) : (
          <div className="col">
            <div className="col col-md-12">
              <div className="btn-label">{text}</div>
            </div>
          </div>
        )}
        <div className="btn-footer col col-xs-6 col-sm-7 col-md-12">
          <div className="row">
            <div className="col col-xs-12">
              <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                type="button"
                className="btn btn-sm btn-link btn-wrap fitv_element slimh_element"
              >Learn more.</a>
            </div>
          </div>
        </div>
      </label>
    </a>
  );
};

AssetType.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  canTradeBoth: PropTypes.bool,
  disabled: PropTypes.bool,
  handleOnChangeAssetType: PropTypes.func,
  modalTarget: PropTypes.string,
  limitText: PropTypes.string,
};

export default AssetType;
