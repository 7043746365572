/* eslint-disable no-prototype-builtins */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, change } from 'redux-form';
import moment from 'moment';
import AgreementsForm from '../components/Agreements/AgreementsForm';
import AgreementsFormValidation from '../validations/AgreementsFormValidation';
import * as registrationActions from '../actions/registrationActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as agreementsActions from '../actions/agreementsActions';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as documentsActions from '../actions/documentsActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as contactActions from '../actions/contactActions';
import * as recaptchaActions from '../actions/recaptchaActions';
import * as applicationActions from '../actions/applicationActions';
import * as contactSearchAndFormat from './contactSearchAndFormat';
import * as financialActions from '../actions/financialActions';
import * as employmentActions from '../actions/employmentActions';
import * as RouteNavigator from './RouteNavigator';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { startCryptoSession } from '../lib/cryptoSessionHelper';

const config = new Config();

const DUMMY_CRYPTO_DISCLAIMER = 'dummy-crypto-disclaimer';

function groupAgreements(group, agreements) {
  const result = agreements.filter((item) => {
    return item.group === group;
  }).sort((a, b) => {
    return (a.sort2 > b.sort2) || (a.sort2 === b.sort2) - 1;
  });
  return result;
}

function sortAgreements(agreements) {
  const result = agreements.sort((a, b) => {
    return (a.sort2 > b.sort2) || (a.sort2 === b.sort2) - 1;
  });
  return result;
}

function getAgreementByKey(key, agreements) {
  const result = agreements.filter((item) => {
    return item.key === key;
  });
  return result && result.length > 0 ? result[0].url : null;
}

export function mapValuesToAgreements(values) {
  let agreements;
  if (values) {
    const signature = values.primaryAccountOwner ? values.primaryAccountOwner.trim() : '';
    const dateSigned = moment().format();
    const jointSignature = values.isJointApplication ? values.jointAccountOwner.trim() : null;
    const dateJointSigned = values.isJointApplication ? moment().format() : null;

    const valuesAgreements = values.agreements.filter(a => a.key !== DUMMY_CRYPTO_DISCLAIMER);

    agreements = {
      agreements: valuesAgreements,
      arbitrationProvisionChoice: values.arbitrationProvision,
      signature,
      dateSigned,
      jointSignature,
      dateJointSigned,
      cryptoEnable: true,
    };
  }
  return agreements;
}

function handleError(error) {
  if (error.response) {
    return error.response.json()
      .then((json) => {
        let errorMessage = json.error.message;
        if (errorMessage.includes('snapshot')) {
          errorMessage = 'Unprocessable Entity';
        }
        throw new SubmissionError({ _error: errorMessage });
      });
  }
  throw new SubmissionError({ _error: error.message });
}

function handleRecatpchaV2Error() {
  const error = 'Unable to load recaptcha';
  throw new SubmissionError({ _error: error });
}

export class AgreementsFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleRecatpchaV2 = this.handleRecatpchaV2.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
  }

  componentDidMount() {
    const {
      agreements,
      actions,
      applicationId,
      authToken,
      contact,
      application,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
      financial,
      employment,
    } = this.props;
    if (!agreements) actions.fetchAgreements(applicationId, authToken);
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!application) actions.fetchApplication(applicationId, authToken);
    if (!employment) actions.fetchEmployment(applicationId, authToken);
    if (!registration || !accountType || !assetClass || !identity || !address || !tradingProfile || !financial) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
        actions.fetchFinancial(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.AGREEMENTS.name, {
            applicationId,
            authToken,
            registration,
            accountType,
            assetClass,
            address,
            identity,
            tradingProfile,
            financial,
          },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.AGREEMENTS.name,
        {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
          financial,
        },
      );
    }

    window.scrollTo(0, 0);
    optimizeHelper.notify();
  }

  handleRecatpchaV2(value) {
    if (value) {
      this.props.actions.setRecaptchaVerified();
      this.props.actions.change('agreements', 'recaptchaError', false);
    } else {
      this.props.actions.unSetRecaptchaVerified();
      this.props.actions.change('agreements', 'recaptchaError', true);
    }
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/summary');
  }

  handleSubmit(values) {
    const agreements = mapValuesToAgreements(values);
    const submit = !this.props.agreementsExists ?
      this.props.actions.submitAgreements :
      this.props.actions.updateAgreements;

    return submit(agreements, this.props.applicationId, this.props.authToken)
      .then(() => {
        startCryptoSession(this.props.cryptoSelected);
        RouteNavigator.push('/thank-you');
      })
      .catch(handleError);
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <AgreementsForm
        {...this.props}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showRecaptchav2={this.props.showRecaptchav2}
        handleRecatpchaV2={this.handleRecatpchaV2}
        handleRecatpchaV2Error={handleRecatpchaV2Error}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

AgreementsFormContainer.propTypes = {
  application: PropTypes.shape(),
  agreements: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  agreementsExists: PropTypes.bool,
  actions: PropTypes.shape({
    fetchAgreements: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    submitAgreements: PropTypes.func.isRequired,
    submitDocumentFile: PropTypes.func.isRequired,
    updateAgreements: PropTypes.func.isRequired,
    setRecaptchaVerified: PropTypes.func.isRequired,
    unSetRecaptchaVerified: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    fetchApplication: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchFinancial: PropTypes.func.isRequired,
    financialActions: PropTypes.func.isRequired,
    fetchEmployment: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    primaryAccountOwner: PropTypes.string,
    jointAccountOwner: PropTypes.string,
  }),
  showRecaptchav2: PropTypes.bool,
  registration: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  financial: PropTypes.shape(),
  employment: PropTypes.shape(),
  cryptoSelected: PropTypes.bool,
};

export function mapStateToInitialValues(state) {
  const accountType = (state && state.accountType) ? state.accountType : null;
  const contacts = (state && state.contact) ? state.contact : null;
  const isJointApplication = (accountType && accountType.accountType.indexOf('joint') === 0);
  const isEntityApplication = (accountType && accountType.accountType.indexOf('entity') === 0);

  let primaryAccountOwner = '';
  let jointAccountOwner = '';
  if (contacts && contacts.length > 0) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', contacts);
    primaryAccountOwner = contactSearchAndFormat.formatContactName(primaryContact);
    if (contacts.length > 1) {
      const jointContact = contactSearchAndFormat.findContactByType('joint', contacts);
      jointAccountOwner = contactSearchAndFormat.formatContactName(jointContact);
    }
  }

  let cryptoSelected = false;
  let equitiesSelected = false;
  let futuresSelected = false;
  let futuresOptionsSelected = false;

  if (state.accountType) {
    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_CRYPTO) > -1) {
      cryptoSelected = true;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) > -1) {
      equitiesSelected = true;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_FUTURES) > -1) {
      futuresSelected = true;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS) > -1) {
      futuresOptionsSelected = true;
    }
  }
  const agreements = state.agreements ? state.agreements.agreements : [];

  const defaultAgreements = {
    agreements,
    agreementsAgreementsAccepted: false,
    exchangeAgreementsAccepted: false,
    cryptoAgreementsAccepted: false,
    arbitrationProvision: null,
    primaryAccountOwner,
    primaryAccountOwnerSignature: '',
    primaryAccountOwnerSignatureDate: moment().format('MM/DD/YYYY'),
    jointAccountOwner,
    jointAccountOwnerSignature: '',
    jointAccountOwnerSignatureDate: moment().format('MM/DD/YYYY'),
    equitiesSelected,
    futuresSelected,
    futuresOptionsSelected,
    cryptoSelected,
    isJointApplication,
    isEntityApplication,
  };

  return defaultAgreements;
}

function mapStateToProps(state) {
  const agreements = state.agreements ? state.agreements.agreements : null;
  const assets = state.accountType ? state.accountType.assetTypes : null;
  const subscriptionAgreementKey = 'tradestation-technologies-subscription-agreement';

  const agreementsCrypto = agreements ? groupAgreements('CRYPTO', agreements) : [];
  let agreementsExchanges = agreements ? groupAgreements('EXCHANGES', agreements) : [];
  let agreementsSecurities = agreements ? groupAgreements('SECURITIES', agreements) : [];
  if (agreementsSecurities.length < 1) {
    agreementsSecurities = agreements ? sortAgreements(agreements) : [];
  }

  if (state && state.contact && state.employment && state.authToken) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', state.contact);
    const primaryEmployment = state.employment.find(e => primaryContact && e.contactId === primaryContact.id);
    const isPro = primaryEmployment && primaryEmployment.isPro;
    const subscriptionAgreement = agreementsSecurities.find(
      agreement => agreement.key === subscriptionAgreementKey);
    const isEquities = assets && assets.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES);
    const isFuturesOrFuturesOptions = assets && (assets.includes(assetTypes.ASSET_CLASS_TYPES_FUTURES) ||
    assets.includes(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS));

    // only include equities exchange agreements for apps that have equities and are non-pro
    if (!isEquities || isPro) {
      const idsToRemove = [120, 110, 160];
      agreementsExchanges = agreementsExchanges.filter(agreement => !idsToRemove.includes(agreement.agreementId));
    }

    // only include futures exchange agreements for apps that have futures or futures options and are non-pro
    if (!isFuturesOrFuturesOptions || isPro) {
      const idsToRemove = [210, 221];
      agreementsExchanges = agreementsExchanges.filter(agreement => !idsToRemove.includes(agreement.agreementId));
    }

    // create urls for exchange agreements
    agreementsExchanges = agreementsExchanges.map((agreement) => {
      return {
        ...agreement,
        url: `${config.apiUrl}${agreement.download}`.concat('?access_token=', state.authToken),
      };
    });

    // move subscriptionAgreement from the 1st to the 2nd list (Software and Data Subscription Agreements)
    if (subscriptionAgreement) {
      agreementsExchanges.unshift(subscriptionAgreement);
      agreementsSecurities = agreementsSecurities.filter(
        agreement => agreement.key !== subscriptionAgreementKey);
    }
  }

  let equitiesSelected = false;
  let equitiesAgreement = null;
  let futuresSelected = false;
  let futuresOptionsSelected = false;
  let futuresAgreement = null;
  let cryptoAgreement = null;
  let cryptoSelected = false;
  let cryptoWaitlisted = false;
  let isUSResidence = false;
  let showRecaptchav2 = false;

  if (state.accountType) {
    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) > -1) {
      equitiesSelected = true;
      equitiesAgreement = agreementsSecurities && agreementsSecurities.length > 0
        ? getAgreementByKey('tradestation-securities-account-agreement-for-securities-accounts', agreements)
        : null;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_FUTURES) > -1) {
      futuresSelected = true;
      futuresAgreement = agreementsSecurities && agreementsSecurities.length > 0
        ? getAgreementByKey('tradestation-securities-account-agreement-for-futures-accounts', agreements)
        : null;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS) > -1) {
      futuresOptionsSelected = true;
      futuresAgreement = agreementsSecurities && agreementsSecurities.length > 0
        ? getAgreementByKey('tradestation-securities-account-agreement-for-futures-accounts', agreements)
        : null;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_CRYPTO) > -1) {
      cryptoSelected = true;
      cryptoAgreement = agreementsCrypto && agreementsCrypto.length > 0
        ? getAgreementByKey('tradestation-crypto-inc-customer-account-agreement', agreements)
        : null;
    }

    if (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST) > -1) {
      cryptoWaitlisted = true;
    }

    if ((state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_EQUITIES) > -1)
      || (state.accountType.assetTypes.indexOf(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST) > -1)) {
      cryptoAgreement = agreementsCrypto && agreementsCrypto.length > 0
        ? getAgreementByKey('tradestation-crypto-inc-customer-account-agreement', agreements)
        : null;
    }
  }

  if (state.contact) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', state.contact);
    isUSResidence = primaryContact.countryOfResidence === 'US';
  }

  if (state.agreements) {
    showRecaptchav2 = state.agreements.challengeRequired;
  }

  const initialValues = mapStateToInitialValues(state);
  return {
    initialValues,
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    agreementsExists: !!state.agreements,
    agreementsSecurities,
    agreementsExchanges,
    agreementsCrypto,
    cryptoAgreement,
    equitiesSelected,
    equitiesAgreement,
    futuresSelected,
    futuresOptionsSelected,
    futuresAgreement,
    cryptoSelected,
    cryptoWaitlisted,
    isJointApplication: initialValues.isJointApplication,
    isEntityApplication: initialValues.isEntityApplication,
    isUSResidence,
    recaptchaSiteKeyV2: config.recaptchaSiteKeyV2,
    showRecaptchav2,
    isRecaptchaVerified: state.isRecaptchaVerified,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      documentsActions,
      agreementsActions,
      accountTypeActions,
      contactActions,
      recaptchaActions,
      applicationActions,
      registrationActions,
      assetClassActions,
      identityActions,
      addressActions,
      tradingProfileActions,
      financialActions,
      employmentActions,
      { change },
    ), dispatch),
  };
}

export function scrollToFirstError(errors) {
  let err;
  if (errors) {
    if (errors.hasOwnProperty('acknowledgeError')) {
      err = 'agreements';
    } else if (errors.hasOwnProperty('arbitrationProvision')) {
      err = 'arbitrationProvision';
    } else if (errors.hasOwnProperty('arbitrationError')) {
      err = 'arbitrationProvision';
    } else if (errors.hasOwnProperty('primaryAccountOwnerSignature')) {
      err = 'primaryAccountOwner';
    } else if (errors.hasOwnProperty('agreementsAgreementsAccepted')) {
      err = 'primaryAccountOwner';
    } else if (errors.hasOwnProperty('exchangeAgreementsAccepted')) {
      err = 'primaryAccountOwner';
    }
  }

  if (err) {
    const el = document.querySelector(`[name="${err}"]`);
    const top = (el.getBoundingClientRect().top + document.documentElement.scrollTop) - 100;
    window.scrollTo({ top, behavior: 'smooth' });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'agreements',
  validate: AgreementsFormValidation,
  onSubmitFail: scrollToFirstError,
})(AgreementsFormContainer));
