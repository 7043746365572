import React from 'react';
import PropTypes from 'prop-types';
import GDPRDisclaimer from './GDPRDisclaimer';
import Config from '../../Config';

const config = new Config();
const paddingTop = {
  paddingTop: '90px',
};

const Intro = (props) => {
  const {
    startApplicationForNewCustomer,
    newApplicationForExistingCustomer,
    continueApplicationForExistingCustomer,
    redirectToLegacyUrl,
  } = props;

  return (
    <div>
      <section className="form-content-section-primary" style={paddingTop} >
        <div className="row text-center">
          { config.introPageVersion === 'V1' ?
            <h1 className="h1_leadForm">
              Let&rsquo;s Get Started
            </h1>
            :
            <div>
              <h2 className="h1_leadForm_v2">
                Welcome to
              </h2>
              <div className="col-md-12 col-xs-12">
                <img className="img-responsive_v2" src="images/logo.png" alt="TradeStation" />
              </div>
            </div>
          }
        </div>
        <div className="text-center">
          { config.introPageVersion === 'V1' &&
          <div>
            <div className="col cols-xs-12 show-lg show-md hidden-xs hidden-sm consent-text">
              <GDPRDisclaimer useLineBreak />
            </div>
            <div className="col cols-xs-12 show-xs show-sm hidden-md hidden-lg">
              <GDPRDisclaimer useLineBreak={false} />
            </div>
          </div>
          }
        </div>
        <div className="row intro-body">
          <div className="col col-md-6 border-intro">
            { config.introPageVersion === 'V1' ?
              <div>
                <h1 className="h2_subHeading h2_subHeadingBorderless text-center">
                  New Customers
                </h1>
                <p className="p_leadForm ">
                  If you are new to TradeStation or don&apos;t have a username and password,
                  click below to open an account.
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-raised btn-default do_capitalize intro-btn"
                    onClick={startApplicationForNewCustomer}
                    id="introRegisterBtn"
                  >
                    register
                  </button>
                </div>
              </div>
            :
              <div className="containerIntroLeft">
                <h1 className="h2_subHeading h2_subHeadingBorderless intro text-center hidden-xs">
                  New Users
                </h1>
                <p className="p_leadForm_v2 hidden-xs">
                  click here to get started
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-raised btn-default do_capitalize intro-btn intro"
                    onClick={startApplicationForNewCustomer}
                    id="introSignUpBtn"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            }
          </div>
          <div className="col col-md-6">
            { config.introPageVersion === 'V1' ?
              <div>
                <h1 className="h2_subHeading h2_subHeadingBorderless text-center">
                  Existing Customers
                </h1>
                <p className="p_leadForm ">
                  If you already have a TradeStation account, or a username and password, get started here.
                </p>
                <div className="text-center">
                  <div className="col col-md-6">
                    <button
                      className="btn btn-raised btn-default do_capitalize intro-btn"
                      onClick={newApplicationForExistingCustomer}
                      id="introAddNewAccountBtn"
                    >
                      add new account
                    </button>
                  </div>
                  <div className="col col-md-6">
                    <button
                      className="btn btn-raised btn-white do_capitalize intro-btn"
                      onClick={continueApplicationForExistingCustomer}
                      id="introResumeAppBtn"
                    >
                      resume application
                    </button>
                  </div>
                </div>
              </div>
            :
              <div className="containerIntroRight">
                <h1 className="h2_subHeading h2_subHeadingBorderless intro text-center hidden-xs">
                  Returning Users
                </h1>
                <p className="p_leadForm_v2 hidden-xs">
                  or to finish an application
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-raised btn-white do_capitalize intro-btn intro"
                    onClick={newApplicationForExistingCustomer}
                    id="introLogInBtn"
                  >
                    Log In
                  </button>
                  <div className="col col-xs-8 col-xs-offset-2 text-center">
                    <p className="p_leadForm_v2 returnText hidden-lg hidden-md hidden-sm">
                      if you are a returning user or to finish an application
                    </p>
                  </div>
                </div>
              </div>
          }
          </div>
        </div>

        <div className="row legacy-message text-center intro-legacy-message" >
          <div className="col col-xs-12">
            { config.introPageVersion === 'V1' ?
              <div>
                To open a <b>Futures IRA</b> account or an <b>Entity</b> account, please {'\u00A0'}
                <a
                  className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
                  onClick={redirectToLegacyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  role="button"
                  tabIndex={0}
                >
                  click here.
                </a>
              </div>
            :
              null
          }
          </div>
        </div>
      </section>
    </div>
  );
};

Intro.propTypes = {
  startApplicationForNewCustomer: PropTypes.func,
  newApplicationForExistingCustomer: PropTypes.func,
  continueApplicationForExistingCustomer: PropTypes.func,
  redirectToLegacyUrl: PropTypes.func,
};

export default Intro;
