/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import AccountType from './AccountType';
import * as accountTypes from './accountTypes';
import EntityAccountType from './EntityAccountType';
import ErrorMessage from '../ErrorMessage';
import JointAccountType from './JointAccountType';
import IRAAccountType from './IRAAccountType';
import Navigation from '../Navigation';
import AssetType from '../AssetClass/AssetType';
import AssetClassEquities from '../AssetClass/AssetClassEquities';
import GenericError from '../GenericError';
import * as assetTypes from '../AssetClass/assetTypes';
import CryptoModal from './CryptoModal';
import CommissionModal from './CommissionModal';
import EquitiesModal from './EquitiesModal';

const assetTypeCompStyle = 'col col-xs-8 col-xs-offset-2 col-sm-4 col-sm-offset-0';
const assetTypeRowStyle = 'row';

const AccountTypeForm = (props) => {
  const {
    selectedAccountType,
    isReturnedApplication,
    assetClassExceptionMessage,
    showIraAccountType,
    isLoading,
    showInheritedIras,
    showSalesRep,
    canTradeEquities,
    canTradeFutures,
    isEquitiesSelected,
    isFuturesSelected,
    isFuturesOptionsSelected,
    isCryptoSelected,
    tradeStockOptionsSelected,
    handleOnChangeRisk,
    handleOnChangeOptions,
    handleOnChangeStrategy,
    handleOnChangeAccountType,
    handleOnChangeAssetType,
    handleCryptoModalToggleOff,
    handleCryptoModalAcknowledgeOff,
    salesReps,
    showCryptoModal,
    handleCommissionModalToggleOff,
    showCommissionModal,
    showEquitiesModal,
    handleEquitiesModalToggleOff,
    countries,
    regions,
    error,
    handleSubmit,
    onSubmit,
    isExistingCustomer,
    handlePhoneChange,
    phoneCountry,
    phoneStatus,
    jointPhone,
    checkNonReduxFormFields,
  } = props;

  const isIRA = selectedAccountType === accountTypes.ACCOUNT_TYPE_IRA;

  return (
    <form id="accounTypeForm" onSubmit={handleSubmit(onSubmit)}>
      <h1 id="page-header" className="h1_secondary text-center">
        Configure Your Accounts
      </h1>
      {isLoading &&
        <div>
          <h1 className="h2_subHeading">
            Account Type
          </h1>
          <div className="panel panel-clear">
            <div className="panel-body">
              <div className={assetTypeRowStyle}>
                <div className={
                  showIraAccountType ? 'col col-sm-3 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'
                    : 'col col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'}
                >
                  <AccountType
                    id="account-type-individual"
                    text="Individual"
                    accountType={accountTypes.ACCOUNT_TYPE_INDIVIDUAL}
                    requirementsUrl="#"
                    modalTargetID="#individual-requirements"
                    handleOnChangeAccountType={handleOnChangeAccountType}
                  />
                </div>
                <div className={
                  showIraAccountType ? 'col col-sm-3 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'
                    : 'col col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'}
                >
                  <AccountType
                    id="account-type-joint"
                    text="Joint"
                    accountType={accountTypes.ACCOUNT_TYPE_JOINT}
                    requirementsUrl="#"
                    modalTargetID="#joint-requirements"
                    handleOnChangeAccountType={handleOnChangeAccountType}
                  />
                </div>
                {showIraAccountType &&
                <div className="col col-sm-3 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button">
                  <AccountType
                    id="account-type-ira"
                    text="IRA"
                    accountType={accountTypes.ACCOUNT_TYPE_IRA}
                    requirementsUrl="#"
                    modalTargetID="#ira-requirements"
                    handleOnChangeAccountType={handleOnChangeAccountType}
                  />
                </div>
                }
                <div className={
                  showIraAccountType ? 'col col-sm-3 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'
                    : 'col col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-2 inside-button'}
                >
                  <AccountType
                    id="account-type-entity"
                    text="Business/Trust"
                    accountType={accountTypes.ACCOUNT_TYPE_ENTITY}
                    requirementsUrl="#"
                    modalTargetID="#entity-requirements"
                    handleOnChangeAccountType={handleOnChangeAccountType}
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedAccountType === accountTypes.ACCOUNT_TYPE_JOINT &&
            <JointAccountType
              isReturnedApplication={isReturnedApplication}
              countries={countries}
              regions={regions}
              handlePhoneChange={handlePhoneChange}
              phoneCountry={phoneCountry}
              phoneStatus={phoneStatus}
              jointPhone={jointPhone}
            />
          }
          {selectedAccountType === accountTypes.ACCOUNT_TYPE_ENTITY && <EntityAccountType />}
          {selectedAccountType === accountTypes.ACCOUNT_TYPE_IRA &&
            <IRAAccountType showInheritedIras={showInheritedIras} />
          }
          {(canTradeEquities || canTradeFutures) &&
            <div>
              <h1 className="h2_subHeading">Accounts to Open</h1>
              {!canTradeEquities && !isIRA &&
                <p className="panel-question text-center">
                  A separate account is required to trade each asset class below.
                </p>
              }
              {isIRA && showInheritedIras &&
                <p className="panel-question text-center">
                  A separate account is required to trade each asset class below.
                </p>
              }
              {assetClassExceptionMessage &&
                <p className="panel-text text-center">
                  {assetClassExceptionMessage}
                </p>
              }
              <div className="panel panel-clear">
                <div className="panel-body">
                  <div className="row">
                    <div className="col col-xs-12 col-xs-offset-0">
                      <div className={assetTypeRowStyle}>
                        <div className={assetTypeCompStyle.concat(' ', 'inside-button')}>
                          <AssetType
                            id="assetTypeEquities"
                            name="assetTypeEquities"
                            text="EQUITIES & OPTIONS"
                            assetType={assetTypes.ASSET_CLASS_TYPES_EQUITIES}
                            linkUrl="https://www.tradestation.com/products/stocks-and-etfs"
                            canTradeBoth={canTradeFutures && canTradeEquities}
                            handleOnChangeAssetType={handleOnChangeAssetType}
                            disabled={!canTradeEquities}
                          />
                        </div>
                        <div className={assetTypeCompStyle.concat(' ', 'inside-button')}>
                          <AssetType
                            id="assetTypeFutures"
                            name="assetTypeFutures"
                            text="FUTURES"
                            assetType={assetTypes.ASSET_CLASS_TYPES_FUTURES}
                            linkUrl="https://www.tradestation.com/products/futures"
                            canTradeBoth={canTradeFutures && canTradeEquities}
                            disabled={!canTradeFutures}
                            handleOnChangeAssetType={handleOnChangeAssetType}
                          />
                        </div>
                        <div className={assetTypeCompStyle.concat(' ', 'inside-button')}>
                          <AssetType
                            id="assetTypeFuturesOptions"
                            name="assetTypeFuturesOptions"
                            text="FUTURES OPTIONS"
                            assetType={assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS}
                            linkUrl="https://www.tradestation.com/products/futures"
                            canTradeBoth={canTradeFutures && canTradeEquities}
                            disabled={!canTradeFutures}
                            handleOnChangeAssetType={handleOnChangeAssetType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center acc-type-err">
                    <GenericError name="assetTypeError" />
                  </div>
                </div>
              </div>
            </div>
          }
          {!isEquitiesSelected && isCryptoSelected &&
            <EquitiesModal
              showEquitiesModal={!showEquitiesModal}
              handleEquitiesModalToggleOff={handleEquitiesModalToggleOff}
            />
          }
          {isEquitiesSelected &&
            <div className="col col-sm-6 col-xs-12">
              <AssetClassEquities
                {...props}
                tradeStockOptionsSelected={tradeStockOptionsSelected}
                handleOnChangeRisk={handleOnChangeRisk}
                handleOnChangeOptions={handleOnChangeOptions}
                handleOnChangeStrategy={handleOnChangeStrategy}
              />
            </div>
          }
          {showCryptoModal &&
            <CryptoModal
              showCryptoModal={showCryptoModal}
              isFuturesSelected={isFuturesSelected}
              isFuturesOptionsSelected={isFuturesOptionsSelected}
              handleCryptoModalToggleOff={handleCryptoModalToggleOff}
              handleCryptoModalAcknowledgeOff={handleCryptoModalAcknowledgeOff}
            />
          }
          {showCommissionModal &&
            <CommissionModal
              showCommissionModal={showCommissionModal}
              handleCommissionModalToggleOff={handleCommissionModalToggleOff}
            />
          }
          <div className="row error-message-padding">
            {error && <ErrorMessage error={error} />}
          </div>
          <Navigation
            onNext={checkNonReduxFormFields}
            showBack={false}
            showSaveForLater={false}
            showSalesRep={showSalesRep}
            salesReps={salesReps}
            isExistingCustomer={isExistingCustomer}
          />
        </div>
      }
    </form>
  );
};

AccountTypeForm.propTypes = {
  selectedAccountType: PropTypes.string,
  isReturnedApplication: PropTypes.bool.isRequired,
  assetClassExceptionMessage: PropTypes.string,
  showIraAccountType: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  showInheritedIras: PropTypes.bool.isRequired,
  showSalesRep: PropTypes.bool.isRequired,
  canTradeEquities: PropTypes.bool.isRequired,
  canTradeFutures: PropTypes.bool.isRequired,
  isEquitiesSelected: PropTypes.bool,
  isFuturesSelected: PropTypes.bool,
  isFuturesOptionsSelected: PropTypes.bool,
  isCryptoSelected: PropTypes.bool,
  tradeStockOptionsSelected: PropTypes.bool,
  handleOnChangeRisk: PropTypes.func.isRequired,
  handleOnChangeOptions: PropTypes.func.isRequired,
  handleOnChangeStrategy: PropTypes.func.isRequired,
  handleCryptoModalToggleOn: PropTypes.func,
  handleCryptoModalToggleOff: PropTypes.func,
  handleCryptoModalAcknowledgeOff: PropTypes.func,
  error: PropTypes.string,
  handleOnChangeAccountType: PropTypes.func,
  handleOnChangeAssetType: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showCryptoModal: PropTypes.bool,
  handleCommissionModalToggleOff: PropTypes.func,
  showCommissionModal: PropTypes.bool,
  showEquitiesModal: PropTypes.bool,
  isExistingCustomer: PropTypes.bool,
  handleEquitiesModalToggleOff: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  phoneCountry: PropTypes.string,
  phoneStatus: PropTypes.string,
  jointPhone: PropTypes.string,
  checkNonReduxFormFields: PropTypes.func,
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default AccountTypeForm;
