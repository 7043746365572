import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PendingApplicationsList from '../components/PendingApplications/PendingApplicationsList';
import * as pendingApplicationsActions from '../actions/pendingApplicationsActions';
import * as applicationIdActions from '../actions/applicationIdActions';
import * as applicationActions from '../actions/applicationActions';
import * as userActions from '../actions/userActions';
import * as RouteNavigator from './RouteNavigator';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as assetTypes from '../components/AssetClass/assetTypes';
import signOutUrl from '../lib/signOutUrl';
import optimizeHelper from '../lib/optimizeHelper';

const capitalize = (txt) => {
  switch (txt) {
    case assetTypes.ASSET_CLASS_TYPES_CRYPTO:
      return 'Crypto';
    case assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST:
      return 'Crypto';
    case assetTypes.ASSET_CLASS_TYPES_EQUITIES:
      return 'Equities';
    case assetTypes.ASSET_CLASS_TYPES_FUTURES:
      return 'Futures';
    case assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS:
      return 'Futures Options';
    default:
      return null;
  }
};

const sortByDate = (applicationA, applicationB) => {
  const dateA = applicationA.created;
  const dateB = applicationB.created;
  if (dateA === dateB) {
    return 0;
  }
  return dateA < dateB ? 1 : -1;
};

const redirectToAccountType = () => {
  RouteNavigator.push('/account-type');
};

const redirectToLastCompletedStep = (step) => {
  const page = mapStepToPage(step);
  RouteNavigator.push(page);
};

export const mapStepToPage = (step) => {
  /*
  ellis model => step
    registration: 1,
    accountType: 2,
    assetClass: 3,
    identity: 4,
    address: 5,
    employment: 6,
    financialProfile: 7,
    tradingProfile: 8,
    iraAccountInfo: 9,
    tradingAuthorization: 10,
    documents: 11,
  */
  const stepToPageMap = {
    1: '/account-type',
    2: '/account-type',
    3: '/account-type',
    4: '/personal-info',
    5: '/personal-info',
    6: '/employment',
    7: '/financial',
    8: '/financial',
    9: '/ira-account',
    10: '/additional-info',
    11: '/documents',
  };
  return stepToPageMap[step] || stepToPageMap[1];
};

export const parseAccount = (account) => {
  if (account && account.accountType) {
    const type = accountTypes.ACCOUNT_TYPES.find(x => x.type === account.accountType);
    const subType = accountTypes.ACCOUNT_SUBTYPES.find(x => x.type === account.accountType);

    return Object.assign({}, account, {
      accountType: type ? type.description : 'Application',
      accountSubtype: subType ? subType.description : '',
      assetTypes: (account.assetTypes || []).map(capitalize).join(' / '),
    });
  }

  return Object.assign({}, account, {
    accountType: 'Application',
    accountSubtype: 'Account Type not provided',
    assetTypes: '',
  });
};

const mapPendingApplications = (application) => {
  const account = application ? parseAccount(application.accountType) : null;

  return {
    id: application ? application.id : '',
    created: new Date(application ? application.created : null),
    company: application && application.employment && application.employment.employmentName,
    account,
    lastCompletedStep: application ? application.lastCompletedStep : 1,
  };
};

export class PendingApplicationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSubmitting: false,
    };
    this.onApplicationSelected = this.onApplicationSelected.bind(this);
    this.startNewApplication = this.startNewApplication.bind(this);
  }

  componentWillMount() {
    const { authToken } = this.props;
    this.props.actions.clearApplicationData();
    this.props.actions
      .fetchUserPendingApplications(authToken)
      .then(() => {
        this.setState({
          isLoading: false,
          isSubmitting: false,
        });
      });
  }

  componentDidMount() {
    optimizeHelper.notify();
  }

  onApplicationSelected(application) {
    const applicationId = application.id;
    this.setState({
      isSubmitting: true,
    });
    this.props.actions.setApplicationId(applicationId);
    redirectToLastCompletedStep(application.lastCompletedStep);
  }

  startNewApplication() {
    const { authToken, userId } = this.props;
    this.setState({
      isSubmitting: true,
    });
    this.props.actions.startApplication(userId, authToken)
      .then(redirectToAccountType)
      .catch(() => {
        this.props.actions.userLogout(this.props.authToken)
        .then(() => {
          window.location = signOutUrl('/logout.html?manual');
        });
      });
  }

  render() {
    return (
      <PendingApplicationsList
        pendingApplications={this.props.pendingApplications}
        onApplicationSelected={this.onApplicationSelected}
        startNewApplication={this.startNewApplication}
        isLoading={this.state.isLoading}
        isSubmitting={this.state.isSubmitting}
      />
    );
  }
}

PendingApplicationsContainer.propTypes = {
  actions: PropTypes.shape({
    fetchUserPendingApplications: PropTypes.func.isRequired,
    setApplicationId: PropTypes.func.isRequired,
    startApplication: PropTypes.func.isRequired,
    clearApplicationData: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
  authToken: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  pendingApplications: PropTypes.arrayOf(PropTypes.shape),
};

function mapStateToProps(state) {
  const pendingApplications = state.pendingApplications ? state.pendingApplications
    .map(mapPendingApplications)
    .sort(sortByDate) : [];

  const isLoading = state.pendingApplications === null;

  return {
    applicationId: state.applicationId,
    pendingApplications: pendingApplications.filter(app => !!app.account),
    authToken: state.authToken,
    userId: state.userId,
    isLoading,
    isSubmitting: state.isSubmitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      pendingApplicationsActions,
      applicationIdActions,
      applicationActions,
      userActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingApplicationsContainer);
