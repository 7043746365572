
import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../Config';
import { accountTypeTitle, accountAssetClassTitleV2 } from '../../lib/constants';
import FuturesIraDisclaimer from '../FuturesIraDisclaimer';
import LeavingTradeStationModal from '../LeavingTradeStationModal';

const config = new Config();

const DepositFunds = (props) => {
  const {
    showFunding,
    showInlineFunding,
    accountsCreated,
    startNewApplication,
    assetClass,
    accountType,
    finishApplication,
    handleShowLeavingTradeStationModal,
    showLeavingTradeStationModal,
    modalTimeLeft,
    endTimerAndModal,
  } = props;
  const { assetTypes } = assetClass;
  const isFuturesIra = accountType
    && accountType.accountType.includes('ira')
    && (assetTypes.includes('futures') || assetTypes.includes('futuresoptions'));
  const hasEquitiesOrCrypto = assetTypes.includes('equities') || assetTypes.includes('crypto');

  const createAccountBox = (account, isFuturesIraAccountBox, isCrypto = false) => {
    const extraWidthForNonFuturesIra = !isFuturesIra ? 'accountBoxExtraWidth' : '';
    return (
      <div className="col col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center deposit-fund-column">
        <div className={`panel panel-default fundingAccountInfoBox ${extraWidthForNonFuturesIra}`}>
          <div className="panel-body accountPanel2">
            <h1 className="account-number accountNumberText">
              {!isFuturesIraAccountBox &&
                <img
                  src="images/green-checkmark-icon.png"
                  alt="Green Checkmark Icon"
                  className="accountBoxIconStyle"
                />
              }
              {isFuturesIraAccountBox &&
                <img
                  src="images/error-icon.png"
                  alt="Red Warning Icon"
                  className="accountBoxIconStyle"
                />
              }
              {isCrypto ? `${account.accountNumber}C` : account.accountNumber}
            </h1>
            <h5 className="account-type text-capitalize accountTypeStyle">
              { accountTypeTitle[account.aopAccountType] }
            </h5>
            <h4 className="accounts-created text-uppercase assetTypeStyle">
              { isCrypto ? 'CRYPTO' : accountAssetClassTitleV2[account.aopAssetClass] }
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const getAccountBoxesV2 = (isFuturesIraAccountBox) => {
    let accountsToMap = accountsCreated;
    let cryptoAccount = [];
    if (assetTypes.find(asset => asset === 'crypto')) {
     cryptoAccount = createAccountBox(accountsCreated.find(account => account.aopAssetClass === 'equities'),
     isFuturesIraAccountBox, true);
    }

    // if account is futures ira, need to separate futures/futuresOptions from equities/crypto assetTypes
    if (isFuturesIra && !isFuturesIraAccountBox) {
      accountsToMap = accountsCreated.filter(i => i.aopAssetClass === 'equities');
    } else if (isFuturesIraAccountBox) {
      accountsToMap = accountsCreated.filter(
        i => i.aopAssetClass === 'futures' || i.aopAssetClass === 'futuresoptions');
    }

    const accounts = accountsToMap.map((account) => {
      return createAccountBox(account, isFuturesIraAccountBox);
    });

    if (!isFuturesIraAccountBox && accountsToMap && accountsToMap.length > 0) {
      accounts.splice(1, 0, cryptoAccount); // put crypto as 2nd box (equities goes first)
    } else {
      accounts.concat(cryptoAccount);
    }

    return accounts;
  };

  return (
    <div>
      <section className="flow-text">
        { (accountsCreated && assetTypes) ?
          <>
            <div className="row">
              <div className="col col-md-12 col-lg-8 col-lg-offset-2">
                <h1 className="h1_leadForm_v2 text-center deposit-funds-title">Thank you for choosing</h1>
              </div>
            </div><div className="row">
              <div className="col col-md-12 col-lg-12 text-center">
                <br /><img src="images/tradestation.png" alt="" className="ts-logo-thankyou-page" /><br /><br />
              </div>
            </div><div className="col col-md-12 col-lg-8 col-lg-offset-2">
              {(hasEquitiesOrCrypto || !isFuturesIra) &&
                <div>
                  <h2 className="h2_subHeading h2_subHeadingBorderless deposit-funds-subtitle">
                    Your Account Numbers
                  </h2>
                  <div className="row groupPanels groupPanelsBackground">
                    {getAccountBoxesV2(false)}
                    <div className="fundingButtonDiv">
                      <button
                        type="submit"
                        id="funding_btn_2"
                        className="btn btn-raised btn-default deposit-funds-btn-main do_capitalize"
                        onClick={(config.inlineFunding === 'V2' || config.inlineFunding === 'V3')
                          ? showInlineFunding
                          : showFunding
                        }
                      >
                        deposit funds
                      </button>
                    </div>
                  </div>
                </div>
              }
              {isFuturesIra &&
                <div>
                  <h2
                    id="fundingSubtitleFuturesIra"
                    className="h2_subHeading h2_subHeadingBorderless deposit-funds-subtitle"
                  >
                    Further Action Required
                  </h2>
                  <div id="futuresIraPanel" className="row groupPanels groupPanelsBackground">
                    <FuturesIraDisclaimer page="funding" />
                    {showLeavingTradeStationModal &&
                      <LeavingTradeStationModal
                        modalTimeLeft={modalTimeLeft}
                        endTimerAndModal={endTimerAndModal}
                      />
                    }
                    {getAccountBoxesV2(true)}
                    <div id="futuresIraBtnDiv">
                      <button
                        type="submit"
                        id="complete_signup_btn"
                        className="btn btn-raised btn-default deposit-funds-btn-main do_capitalize"
                        onClick={handleShowLeavingTradeStationModal}
                      >
                        Complete Sign-Up
                      </button>
                      <button
                        type="submit"
                        id="finish_later_btn"
                        className="btn btn-raised btn-white do_capitalize"
                        onClick={finishApplication}
                      >
                        Finish Later
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div><div>
              <div className="row">
                <br /><br /><br />
              </div>
            </div>
          </>
          :
          <div className="container">
            <div className="row">
              <div className="col col-md-12 col-lg-8 col-lg-offset-2">
                <h1 className="h1_secondary text-center deposit-funds-header">
                  Welcome to TradeStation!
                </h1>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
                  <p className="p_leadForm deposit-funds-text">
                    Your application has been submitted.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="deposit-funds-divider" />
              </div>
              <div className="row">
                <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
                  <div>
                    <h1 className="control-label deposit-funds-subtitle">
                      Ready to get started?
                    </h1>
                    <button
                      type="submit"
                      id="funding_btn"
                      className="btn btn-raised btn-default deposit-funds-btn-main do_capitalize"
                      onClick={showFunding}
                    >
                      deposit funds
                    </button>
                    <br />
                    <button
                      type="submit"
                      id="next_btn"
                      className="btn deposit-funds-btn-second do_capitalize"
                      onClick={startNewApplication}
                    >
                      start a new application
                    </button>
                    <br /><br /><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </section>
    </div>
  );
};

DepositFunds.propTypes = {
  showFunding: PropTypes.func.isRequired,
  showInlineFunding: PropTypes.func.isRequired,
  accountsCreated: PropTypes.arrayOf(PropTypes.shape({
    aopAssetClass: PropTypes.string.isRequired,
    aopAccountType: PropTypes.string.isRequired,
    crmAssetClass: PropTypes.number.isRequired,
    crmAccountType: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })),
  startNewApplication: PropTypes.func.isRequired,
  assetClass: PropTypes.arrayOf.isRequired,
  accountType: PropTypes.shape().isRequired,
  finishApplication: PropTypes.func.isRequired,
  handleShowLeavingTradeStationModal: PropTypes.func.isRequired,
  showLeavingTradeStationModal: PropTypes.bool.isRequired,
  modalTimeLeft: PropTypes.number.isRequired,
  endTimerAndModal: PropTypes.func.isRequired,
};

export default DepositFunds;
