import React from 'react';
import { withRouter } from 'react-router';

const CryptoFooter = () => (
  <section className="form-content-section-primary flow-text" id="crypto-footer">
    <div className="container">
      <div className="row">
        <div className="col col-md-12 col-lg-10 col-lg-offset-1 footer-text">
          <b>
            TradeStation Securities, Inc.
          </b> and&nbsp;
          <b>TradeStation Technologies, Inc.
          </b> are each wholly owned subsidiaries of <b>TradeStation Group, Inc.</b>,
          both operating, and providing products and services, under the TradeStation brand and trademark.&nbsp;
          <b className="italic">
            When applying for, or
            purchasing, accounts, subscriptions, products and services, it is important that
            you know which company you will be dealing with.
          </b>
          <b>&nbsp;
            <a
              className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
              tabIndex="-1"
              href="https://uploads.tradestation.com/uploads/tradestation-group-inc-companies.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
          &nbsp;for further important information explaining what this means.</b>
        </div>
      </div>
    </div>
  </section>
);

const Footer = (location) => {
  const pathname = location && location.location && location.location.pathname ? location.location.pathname : '';

  const checkPage = () => {
    const isIntroPage = window.location.pathname === '/intro';
    const isInlineFundingPage = window.location.pathname === '/funding';
    const isThankYouPage = window.location.pathname === '/thank-you';

    if (isIntroPage || isInlineFundingPage || isThankYouPage) {
      return false;
    }
    return true;
  };

  return (
    <section className="bottom-footer-section" id="bottom-footer-section">
      {(checkPage()) &&
        <div className="navbar fitv_element">
          <div className="container">
            <div className="navbar-header">
              <span className="navbar-brand centered">
                <b>Need assistance?</b> &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tradestation.com/contact-us/"
                >
                  Contact us.
                </a>
              </span>
            </div>
          </div>
        </div>
      }
      {pathname === '/intro' &&
        <CryptoFooter />
      }
    </section>
  );
};

export default withRouter(Footer);
