import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/Summary/SummarySection';
import * as iraAccountActions from '../../actions/iraAccountActions';
import iraBeneficiaryTexts from '../../components/IraAccount/IraBeneficiaryTexts';
import * as beneficiaryTypes from '../../components/IraAccount/IraBeneficiaryRelationshipTypes';
import * as summaryRowTypes from '../../components/Summary/summaryRowTypes';

const tab = '\u00A0\u00A0\u00A0\u00A0';

export const parseDate = (dateValue) => {
  const d = dateValue ? dateValue.toString().slice(0, 10).split('-') : [];
  return d.length === 3 ? `${d[1]}/${d[2]}/${d[0]}` : '';
};

export class SummaryIRAAccountContainer extends React.Component {
  componentDidMount() {
    if (!this.props.iraAccount) {
      this.props.actions.fetchIraAccount(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryIRAAccountContainer.propTypes = {
  actions: PropTypes.shape({
    fetchIraAccount: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  iraAccount: PropTypes.shape(),
};

export const getBeneficiaryRelationship = (relationshipTexts, relationshipCode) => {
  return summaryValueMapper.getTextFromValue(relationshipTexts, relationshipCode || '');
};

export function mapIRABeneficiariesInfo(iraAccount, hasFutures, hasEquities) {
  let iraAccountInformation = [];
  const hasEquitiesAndFutures = hasFutures && hasEquities;
  const hasOnlyFutures = hasFutures && !hasEquities;
  if (iraAccount && iraAccount.iraBeneficiaries && iraAccount.iraBeneficiaries.length > 0 && !hasOnlyFutures) {
    let primaryCount = 0;
    iraAccount.iraBeneficiaries.forEach((beneficiary, index) => {
      primaryCount += beneficiary.type === 'primary' ? 1 : 0;
      const newIndex = beneficiary.type === 'primary' ? index : (index - primaryCount);
      iraAccountInformation = iraAccountInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_H5,
          valueType: summaryRowTypes.SUMMARY_ROW_NONE,
          key: hasEquitiesAndFutures ? `${beneficiary.type.toUpperCase()} BENEFICIARY #${newIndex + 1} - EQUITY IRA` :
          `${beneficiary.type.toUpperCase()} BENEFICIARY #${newIndex + 1}`,
          value: '',
        },
      ]);
      iraAccountInformation = iraAccountInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_TXT,
          key: `${tab}FULL NAME`,
          value: beneficiary.fullName,
        },
      ]);
      iraAccountInformation = iraAccountInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_TXT,
          key: `${tab}RELATIONSHIP`,
          value: getBeneficiaryRelationship(iraBeneficiaryTexts, beneficiary.relationship),
        },
      ]);
      if (beneficiary.relationship !== beneficiaryTypes.IRA_RELATIONSHIP_ESTATE &&
        beneficiary.relationship !== beneficiaryTypes.IRA_RELATIONSHIP_ORGANIZATIONCHARIRY) {
        iraAccountInformation = iraAccountInformation.concat([
          {
            keyType: summaryRowTypes.SUMMARY_ROW_TXT,
            valueType: summaryRowTypes.SUMMARY_ROW_TXT,
            key: `${tab}SOCIAL SECURITY NUMBER`,
            value: beneficiary.ssn,
          },
        ]);
        iraAccountInformation = iraAccountInformation.concat([
          {
            keyType: summaryRowTypes.SUMMARY_ROW_TXT,
            valueType: summaryRowTypes.SUMMARY_ROW_TXT,
            key: `${tab}DATE OF BIRTH`,
            value: parseDate(beneficiary.dateOfBirth),
          },
        ]);
      }
      iraAccountInformation = iraAccountInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_TXT,
          key: `${tab}PERCENTAGE`,
          value: `${beneficiary.share}%`,
        },
      ]);
    });
  }
  return iraAccountInformation;
}

function mapStateToInitialValues(state) {
  let isLoading = true;
  let iraHasFutures = false;
  let iraHasEquities = false;

  if (state.iraAccountInfo) {
    isLoading = false;
  }

  const iraAccount = state.iraAccount || {};

  if (state.assetClass) {
    iraHasFutures = state.assetClass.assetTypes.includes('futures')
      || state.assetClass.assetTypes.includes('futuresoptions');
    iraHasEquities = state.assetClass.assetTypes.includes('equities');
  }

  return {
    isLoading,
    key: 'iraAccount',
    value: mapIRABeneficiariesInfo(iraAccount, iraHasFutures, iraHasEquities),
    title: 'BENEFICIARIES',
    editLink: '/ira-account',
    iraHasFutures,
    iraHasOnlyFutures: iraHasFutures && !iraHasEquities,
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    iraHasFutures: initialValues.iraHasFutures,
    iraHasOnlyFutures: initialValues.iraHasOnlyFutures,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, iraAccountActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryIRAAccountContainer);
