import React from 'react';
import AccountTypeFormContainer from '../../containers/AccountTypeFormContainer';
import ProgressBar2 from '../ProgressBar2';

const AccountTypePage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appAccountType" className="col col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <AccountTypeFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountTypePage;
