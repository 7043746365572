import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InlineFundingNavigation from '../InlineFundingNavigation';
import InlineFundingNavigationTransition from '../InlineFundingNavigationTransition';
import Config from '../../Config';
import AcatFrame from './AcatFrame';

const config = new Config();

const ACATPage = (props) => {
  const { onBack, authToken, acatAppUrl } = props;
  const [showFundingNavigationTransition, setShowFundingNavigationTransition] = useState(false);
  const [hideFooterNav, setHideFooterNav] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === config.acatAppUrl || config.acatAppUrl.includes(event.origin)) {
        if (event.data.page === 'request-recieve') {
          setHideFooterNav(true);
          setShowFundingNavigationTransition(true);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleTransferButtonClicked = () => {
    window.location = `${config.getStartedBaseUrl}/funding`;
  };
  const handleLetsGoButtonClicked = () => {
    window.location = `${config.hubBaseUrl}`;
  };

  return (
    <div className="acat-container">
      <AcatFrame acatAppUrl={acatAppUrl} authToken={authToken} lastPage={showFundingNavigationTransition} />
      {showFundingNavigationTransition && <div className="row">
        <InlineFundingNavigationTransition
          assistenceText=""
          onNewTransferButtonClicked={handleTransferButtonClicked}
          onLetsGoButtonClicked={handleLetsGoButtonClicked}
          noBorder
        />
      </div>}
      {!hideFooterNav &&
        <div className="row">
          <InlineFundingNavigation backButtonId="acat-back-btn" onBack={onBack} backLabel="Transfer Methods" hideBackButton={showFundingNavigationTransition} />
        </div>
      }
    </div>
  );
};

ACATPage.propTypes = {
  onBack: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  acatAppUrl: PropTypes.string.isRequired,
};

export default ACATPage;
