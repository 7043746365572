import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../../DropDownList';
import TextBox from '../../TextBox';
import { normalizeSSN, onMaskingSSN } from './normalizeSSN';


const IdentityFormSection = (props) => {
  const {
    sectionIndex,
    identityCountries,
    months,
    days,
    yearsBirthdate,
    showIdentificationSSN,
    isFieldEnabled,
  } = props;

  const isEnabled = isFieldEnabled[sectionIndex] || {};

  return (
    <div>
      <div id="identity" className="col col-md-12">
        <DropDownList
          fieldName="countryOfCitizenship"
          className="form-control"
          label="Country of Citizenship"
          options={identityCountries}
          disabled={isEnabled.countryOfResidence === false}
        />
        <div className="row">
          <div className="col col-xs-12">
            <span className="control-label">Date of Birth</span>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-5 date-divider">
            <DropDownList
              fieldName="birthMonth"
              className="form-control"
              label="Month"
              options={months}
              disabled={isEnabled.birthMonth === false}
            />
          </div>
          <div className="col col-xs-3 date-divider">
            <DropDownList
              fieldName="birthDay"
              className="form-control"
              label="Day"
              options={days}
              disabled={isEnabled.birthDay === false}
            />
          </div>
          <div className="col col-xs-4">
            <DropDownList
              fieldName="birthYear"
              className="form-control"
              label="Year"
              options={yearsBirthdate}
              disabled={isEnabled.birthYear === false}
            />
          </div>
        </div>
        {showIdentificationSSN[sectionIndex] &&
          <TextBox
            name="identificationSSN"
            className="form-control"
            label="Social Security Number / ITIN"
            maxLength={11}
            normalize={normalizeSSN}
            onMasking={onMaskingSSN}
            disabled={isEnabled.identificationSSN === false && sectionIndex === 0}
          />
        }
      </div>
    </div>
  );
};

IdentityFormSection.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  identityCountries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  months: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  days: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  yearsBirthdate: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  showIdentificationSSN: PropTypes.arrayOf(PropTypes.bool).isRequired,
  isFieldEnabled: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default IdentityFormSection;
