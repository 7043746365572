import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactTooltip from 'react-tooltip';
import DropDownList from '../DropDownList';
import ErrorMessage from '../ErrorMessage';
import TextBox from '../TextBox';
import PasswordFieldV2 from './PasswordFieldV2';
import ExistingAccountModal from '../ExistingAccountModal';
import DuplicateRemediationModal from '../DuplicateRemediationModal';
import LoggedAccountErrorModal from './LoggedAccountErrorModal';
import SupportedSymbolsModal from './SupportedSymbolsModal';
import PromoCodeBanner from './PromoCodeBanner';
import GDPRDisclaimer from '../Intro/GDPRDisclaimer';
import * as storageKeys from '../../actions/storageKeys';
import * as RouteNavigator from '../../containers/RouteNavigator';
import { getBrowserOrigin } from '../../lib/browser';
import Config from '../../Config';
import RegistrationCredentialsFormLoading from './RegistrationCredentialsFormLoading';

const config = new Config();
const REDIRECT_URL = `${config.apiUrl}/auth/tradestation?redirectTo=`;
const ACCOUNT_TYPE_URL = '/account-type';

export const isFailedLogin = () => {
  const login = RouteNavigator.getURLParameterByName(storageKeys.LOGIN);
  if (!login) {
    return false;
  }
  return true;
};

const failedLogin = isFailedLogin();

const handleLogin = () => {
  const redirectTo = getBrowserOrigin();
  window.location = encodeURI(REDIRECT_URL.concat(redirectTo).concat(ACCOUNT_TYPE_URL));
};

const RegistrationCredentialsForm = (props) => {
  const {
    countries,
    error,
    handleSubmit,
    onSubmit,
    handleLoggedAccountErrorModalToggleOff,
    regions,
    showExistingAccountModal,
    showLoading,
    showLoggedAccountErrorModal,
    hearAboutUsOptions,
    salesReps,
    hearAboutUsOtherSelected,
    hearAboutUsSalesRepSelected,
    passwordVisible,
    confirmPasswordVisible,
    handleShowHidePassword,
    handleShowHideConfirmPassword,
    missingContactData,
    contactMissingFieldList,
    isSubmitting,
    onBack,
    handleCountryChange,
    handlePhoneChange,
    checkNonReduxFormFields,
    phoneCountry,
    phoneStatus,
    showPasswordIcons,
    handleShowHidePasswordIcons,
    handleSupportedSymbolsModalToggleOn,
    handleSupportedSymbolsModalToggleOff,
    showSupportedSymbolsModal,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showExistingAccountModal && (
        <div>
          {config.duplicateRemediationEnabled &&
            <DuplicateRemediationModal page="registration" />
          }
          {!config.duplicateRemediationEnabled &&
            <ExistingAccountModal
              message="An account with this personal information already exists. Please login to continue."
            />
          }
        </div>
      )}
      {showLoading && (
        <div>
          <RegistrationCredentialsFormLoading />
          {error && <ErrorMessage error={error} />}
        </div>
      )}
      {!showLoading && (
        <div>
          <div className="row">
            <div className="col col-md-12 text-center">
              <h1 className="h1_secondary text-center">
                Account Setup
              </h1>
              {(!failedLogin && !missingContactData) && <span>
                <span className="btn-label">Current client or finishing an application? </span>
                <span>
                  <button
                    id="loginButton"
                    type="button"
                    className="btn btn-raised btn-default btn-sm do_capitalize"
                    onClick={handleLogin}
                  >
                    log in
                  </button>
                </span>
              </span>
              }
            </div>
          </div>
          <div className="row">
            <div className="col col-md-12">
              <h1 className="h2_subHeading">Customer Profile</h1>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-12">
              <p>
                Enter your name exactly as it appears on your driver&apos;s license (U.S. only)
                or government-issued ID (e.g. passport).
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-4 col-sm-4 col-xs-9">
              <TextBox
                id="firstName"
                name="firstName"
                className="form-control"
                label="First Name"
                maxLength={30}
                disabled={missingContactData}
              />
            </div>
            <div className="col col-lg-2 col-sm-2 col-xs-3">
              <TextBox
                id="middleInitial"
                name="middleInitial"
                className="form-control"
                label="M.I."
                maxLength={1}
              />
            </div>
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              <TextBox
                id="lastName"
                name="lastName"
                className="form-control"
                label="Last Name"
                maxLength={30}
                disabled={missingContactData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              <DropDownList
                id="countryOfResidence"
                fieldName="countryOfResidence"
                className="form-control"
                label="Country of Legal Residence"
                handleChange={handleCountryChange}
                options={countries}
                disabled={missingContactData && !contactMissingFieldList.includes('countryOfResidence')}
              />
            </div>
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              <DropDownList
                id="regionOfResidence"
                fieldName="regionOfResidence"
                className="form-control"
                label="State or Province"
                options={regions}
                disabled={!regions
                  || (regions.length === 1 && regions[0].value === '')
                  || (missingContactData && !contactMissingFieldList.includes('regionOfResidence'))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              <TextBox
                id="email"
                type="email"
                name="email"
                className="form-control"
                label="Email Address"
                maxLength={50}
                disabled={missingContactData}
              />
            </div>
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              { phoneCountry === 'je' &&
                <PhoneInput
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  maxLength={25}
                  inputClass={phoneStatus === 'false' ? 'phoneInput phoneError' : 'phoneInput'}
                  countryCodeEditable={false}
                  enableSearch
                  enableTerritories
                  placeholder="Phone Number"
                  priority={{ je: 0, gb: 1 }}
                  country={phoneCountry}
                  onChange={phone => handlePhoneChange(phone)}
                />
              }
              { phoneCountry !== 'je' &&
                <PhoneInput
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  maxLength={25}
                  inputClass={phoneStatus === 'false' ? 'phoneInput phoneError' : 'phoneInput'}
                  countryCodeEditable={false}
                  enableSearch
                  enableTerritories
                  placeholder="Phone Number"
                  priority={{ gb: 0, je: 1 }}
                  country={phoneCountry}
                  onChange={phone => handlePhoneChange(phone)}
                />
              }
              <div className="form-group label-floating has-error">
                <div className="messages">
                  {phoneStatus === 'false' &&
                    <p className="help-block error phoneErrorMessage">Not a valid Phone Number</p>
                  }
                  {phoneStatus === 'empty' &&
                    <p className="help-block error phoneRequiredMessage">Required</p>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              <DropDownList
                id="hearAboutUs"
                fieldName="hearAboutUs"
                className="form-control"
                label="How did you hear about us?"
                options={hearAboutUsOptions}
              />
            </div>
            <div className="col col-lg-6 col-sm-6 col-xs-12">
              {hearAboutUsOtherSelected &&
                <TextBox
                  id="hearAboutUsOther"
                  name="hearAboutUsOther"
                  className="form-control"
                  label="Specify"
                  maxLength={30}
                />
              }
              {hearAboutUsSalesRepSelected &&
                <DropDownList
                  id="hearAboutUsSalesRep"
                  fieldName="hearAboutUsSalesRep"
                  className="form-control"
                  label="Sales Rep. Name"
                  options={salesReps}
                />
              }
            </div>
          </div>
        </div>
      )}
      {showLoggedAccountErrorModal &&
        <LoggedAccountErrorModal
          showLoggedAccountErrorModal={showLoggedAccountErrorModal}
          handleLoggedAccountErrorModalToggleOff={handleLoggedAccountErrorModalToggleOff}
        />
      }
      {!missingContactData && !showLoading &&
        <div>
          <h1 className="h2_subHeading">Login Credentials</h1>
          <div className="row">
            <div className="col col-xs-12">
              <TextBox
                id="username"
                name="username"
                className="form-control"
                label="Username"
                maxLength={30}
                tooltip
              />
              <ReactTooltip id="username" place="left" class="tooltip">
                <span>
                  Username must be between 5 - 30 characters and only allows letters and numbers.
                </span>
              </ReactTooltip>
              <SupportedSymbolsModal
                showSupportedSymbolsModal={showSupportedSymbolsModal}
                handleSupportedSymbolsModalToggleOff={handleSupportedSymbolsModalToggleOff}
              />
              <PasswordFieldV2
                id="password"
                name="password"
                type="password"
                className="form-control"
                label="Password"
                maxLength={23}
                tooltip
                pwdVisible={passwordVisible}
                handleShowHidePassword={handleShowHidePassword}
                showPasswordIcons={showPasswordIcons}
                handleShowHidePasswordIcons={handleShowHidePasswordIcons}
                handleSupportedSymbolsModalToggleOn={handleSupportedSymbolsModalToggleOn}
              />
              {config.credentialsValidationVersion === 'V1' &&
                <ReactTooltip id="password" place="left" class="tooltip">
                  <span>
                    Password must be between 7 - 23 characters and must contain a combination of letters and numbers.
                    Spaces are not valid.
                  </span>
                </ReactTooltip>
              }
              <PasswordFieldV2
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="form-control"
                label="Confirm Password"
                maxLength={23}
                tooltip
                pwdVisible={confirmPasswordVisible}
                handleShowHidePassword={handleShowHideConfirmPassword}
                showPasswordIcons={false}
                handleShowHidePasswordIcons={handleShowHidePasswordIcons}
              />
              {config.credentialsValidationVersion === 'V1' &&
                <ReactTooltip id="confirmPassword" place="left" class="tooltip">
                  <span>
                    Password must be between 7 - 23 characters and must contain a combination of letters and numbers.
                    Spaces are not valid.
                  </span>
                </ReactTooltip>
              }
            </div>
          </div>
        </div>
      }

      {!showLoading &&
        <PromoCodeBanner />
      }
      {!showLoading && config.introPageVersion === 'V2' &&
        <div className="text-center">
          <div className="col cols-xs-12 show-lg show-md consent-text">
            <GDPRDisclaimer useLineBreak />
          </div>
        </div>
      }

      {!showLoading &&
        <div className={config.introPageVersion !== 'V1' ?
        'col cols-xs-12 grecaptcha-policy-v2 text-center' :
        'col cols-xs-12 grecaptcha-policy text-center'}
        >
          This site is protected by reCAPTCHA and the Google&nbsp;
          <a
            href="https://policies.google.com/privacy"
            className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy</a> and&nbsp;
          <a
            href="https://policies.google.com/terms"
            className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service</a> apply.
        </div>
      }
      {error && <ErrorMessage error={error} />}

      { !showLoading &&
        <div className="col col-sm-12 text-right btn-container-xs">
          <button
            type="button"
            id="back_btn"
            className="btn btn-default do_capitalize back-button"
            disabled={isSubmitting || showLoading}
            onClick={onBack}
          >
            back
          </button>
          <button
            type="submit"
            id="next_btn"
            className="btn btn-raised btn-default do_capitalize"
            disabled={isSubmitting}
            onClick={checkNonReduxFormFields}
          >
            next
            { isSubmitting && phoneStatus === 'true'
              && <span className="glyphicon glyphicon-refresh spinning" /> }
          </button>
        </div>
      }

      { showLoading &&
        <div className="col col-sm-12 text-right btn-container-xs" >
          &nbsp;
        </div>
      }
    </form>
  );
};

RegistrationCredentialsForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleLoggedAccountErrorModalToggleOff: PropTypes.func.isRequired,
  handleSupportedSymbolsModalToggleOn: PropTypes.func.isRequired,
  handleSupportedSymbolsModalToggleOff: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  showExistingAccountModal: PropTypes.bool,
  showLoading: PropTypes.bool,
  showLoggedAccountErrorModal: PropTypes.bool,
  hearAboutUsOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  hearAboutUsOtherSelected: PropTypes.bool,
  hearAboutUsSalesRepSelected: PropTypes.bool,
  passwordVisible: PropTypes.bool,
  confirmPasswordVisible: PropTypes.bool,
  handleShowHidePassword: PropTypes.func,
  handleShowHideConfirmPassword: PropTypes.func,
  missingContactData: PropTypes.bool,
  contactMissingFieldList: PropTypes.arrayOf(PropTypes.string),
  isSubmitting: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  checkNonReduxFormFields: PropTypes.func,
  phoneCountry: PropTypes.string,
  phoneStatus: PropTypes.string,
  showPasswordIcons: PropTypes.bool,
  handleShowHidePasswordIcons: PropTypes.func,
  showSupportedSymbolsModal: PropTypes.bool,
};

export default RegistrationCredentialsForm;
