/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import AgreementsList from './AgreementsList';
import AgreementsInstructions from './AgreementsInstructions';
import AgreementsDisclaimer1 from './AgreementsDisclaimer1';
import AgreementsCryptoDisclaimer from './AgreementsCryptoDisclaimer';
import AgreementsInformation from './AgreementsInformation';
import AgreementsInformationSubmit from './AgreementsInformationSubmit';
import AgreementsArbitrationEquities from './AgreementsArbitrationEquities';
import AgreementsArbitrationFutures from './AgreementsArbitrationFutures';
import AgreementsSignature from './AgreementsSignature';
import GenericError from '../GenericError';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import AgreementsFooter from './AgreementsFooter';
import Footer from '../Footer';
import ExitModal from '../ExitModal';

const AgreementsForm = (props) => {
  const {
    error,
    handleSubmit,
    onSubmit,
    onBack,
    agreementsSecurities,
    agreementsExchanges,
    cryptoAgreement,
    agreementsCrypto,
    equitiesSelected,
    equitiesAgreement,
    futuresSelected,
    futuresOptionsSelected,
    futuresAgreement,
    cryptoSelected,
    cryptoWaitlisted,
    isJointApplication,
    isUSResidence,
    recaptchaSiteKeyV2,
    showRecaptchav2,
    handleRecatpchaV2,
    handleRecatpchaV2Error,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  return (
    <div>
      <div id="agreements-form" >
        <h1 className="h1_secondary text-center">Account Agreements</h1>
        <p className="text-center ">
          Here&apos;s what you need to do to complete your account application.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AgreementsInstructions />
          <div className="row">
            <div className="col col-md-12 agreements-acknowledge-error">
              <GenericError
                name="acknowledgeError"
              />
            </div>
          </div>
          <div name="agreements">
            <AgreementsList
              groupTitle="Account and User Agreements, Disclosures, Disclaimers and Assumptions of Risk"
              list={agreementsSecurities}
              name="agreementsAgreementsAccepted"
              id="agreementsAgreementsCheck"
            />
            <AgreementsList
              groupTitle="Software and Data Subscription Agreements"
              list={agreementsExchanges}
              name="exchangeAgreementsAccepted"
              id="exchangeAgreementsCheck"
            />
          </div>
          {(cryptoSelected && !cryptoWaitlisted) &&
            <div>
              <h4 className="agreement-subtitle">
                TradeStation Crypto, Inc. Accounts
            </h4>
              <AgreementsList
                groupTitle="Agreements, Disclosures, Disclaimers and Assumption of Risk"
                list={agreementsCrypto}
                name="cryptoAgreementsAccepted"
                id="cryptoAgreementsCheck"
              />
            </div>
          }
          {(cryptoSelected && !cryptoWaitlisted && isUSResidence) &&
            <div>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  // eslint-disable-next-line max-len
                  href="https://uploads.tradestation.com/uploads/us-states-where-tradestation-crypto-is-licensed-or-permitted-to-do-business.pdf"
                >
                  Click here for: U.S. States Where TradeStation Crypto is Licensed or Permitted to do Business and
                  Related Notices and Information.
                </a>
                &nbsp;You acknowledge that you have reviewed the information
                applicable to your state contained in the above link.
              </p>
            </div>
          }
          <AgreementsDisclaimer1 />
          { (cryptoSelected && !cryptoWaitlisted) &&
            <AgreementsCryptoDisclaimer />
          }
          {equitiesSelected &&
            <AgreementsArbitrationEquities equitiesAgreement={equitiesAgreement} cryptoAgreement={cryptoAgreement} />
          }
          {(futuresSelected || futuresOptionsSelected) &&
            <AgreementsArbitrationFutures futuresAgreement={futuresAgreement} />
          }
          <AgreementsInformation />
          <div className="row">
            <AgreementsSignature isJoint={false} />
            {!isJointApplication &&
              <div className="col col-md-4 col-md-offset-2">
                <img
                  src="images/verify-identity-individual.png"
                  alt="Account Holder"
                  className="centered hidden-sm hidden-xs"
                />
              </div>
            }
            {isJointApplication &&
              <AgreementsSignature isJoint />
            }
          </div>
          <AgreementsInformationSubmit />
          {showRecaptchav2 &&
            <div className="row">
              <div className="col col-md-6">
                <ReCAPTCHA
                  className="recaptcha-box"
                  name="recaptcha-box"
                  id="recaptcha-box"
                  sitekey={recaptchaSiteKeyV2}
                  onChange={handleRecatpchaV2}
                  onErrored={handleRecatpchaV2Error}
                />
                <GenericError className="center-div" name="recaptchaError" />
              </div>
            </div>
          }
          {error && <ErrorMessage error={error} />}
          {showExitModal && (
            <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
          )}
          <div className="row">
            <div className="col col-xs-12">
              <Navigation onBack={onBack} submit="submit" onExitModalOn={onExitModalOn} />
            </div>
          </div>
          <Footer />
          <AgreementsFooter />
        </form>
      </div>
    </div>
  );
};

AgreementsForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  agreementsSecurities: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })),
  agreementsExchanges: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })),
  agreementsCrypto: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })),
  equitiesSelected: PropTypes.bool.isRequired,
  cryptoAgreement: PropTypes.string,
  equitiesAgreement: PropTypes.string,
  futuresSelected: PropTypes.bool.isRequired,
  futuresOptionsSelected: PropTypes.bool.isRequired,
  futuresAgreement: PropTypes.string,
  cryptoSelected: PropTypes.bool,
  cryptoWaitlisted: PropTypes.bool,
  isJointApplication: PropTypes.bool.isRequired,
  isUSResidence: PropTypes.bool,
  recaptchaSiteKeyV2: PropTypes.string,
  showRecaptchav2: PropTypes.bool.isRequired,
  handleRecatpchaV2: PropTypes.func,
  handleRecatpchaV2Error: PropTypes.func,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default AgreementsForm;
