import validatePhone from './PhoneValidation';
import validatePassword from './PasswordValidationV2';
import * as hearAboutUsHelper from '../lib/containerHelpers/registration/hearAboutUsHelper';
import validateEmail from '../validations/EmailValidation';

export const HEAR_ABOUT_US_SOURCE_OTHER = 'other';
export const HEAR_ABOUT_US_SALES_REP = 'working-with-a-tradeStation-rep';
const onlyNumbersPattern = /^\d+$/;
const monexUsernamePattern = /^[m-nM-N][0-9]*$/;

function validatePromoCode(promoCode) {
  return promoCode && (/[0-9a-zA-Z]{8}/g).test(promoCode);
}

export const validate = (values, props) => {
  const errors = {};

  const credentials = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    username: values.username,
    password: values.password,
  };

  if (!values.countryOfResidence) {
    errors.countryOfResidence = 'Required';
  }

  if (values.countryOfResidence) {
    const country = props.countries.find(c => c.countryCode === values.countryOfResidence);
    if (country.regions && country.regions.length > 0) {
      if (!values.regionOfResidence) {
        errors.regionOfResidence = 'Required';
      }
    }
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Not a valid email address';
  }

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else {
    const firstName = values.firstName.trim();
    if (!/^[a-zA-Z][a-zA-Z0-9ñáéíóúü.'\s-]{1,30}$/i.test(firstName)) {
      errors.firstName = 'Not a valid first name';
    }
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else {
    const lastName = values.lastName.trim();
    if (!/^[a-zA-Z][a-zA-Z0-9ñáéíóúü.'\s-]{1,30}$/i.test(lastName)) {
      errors.lastName = 'Not a valid last name';
    }
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else {
    errors.phone = validatePhone(values.phone, values.countryOfResidence);
  }

  if (values.middleInitial) {
    if (!/^[a-zA-Z]?$/i.test(values.middleInitial)) {
      errors.middleInitial = 'Not a valid initial';
    }
  }

  if (values.promoCode) {
    if (values.promoCode.length < 8) {
      errors.promoCode = 'This code is invalid';
    } else if (values.promoCode.length >= 8 && !validatePromoCode(values.promoCode)) {
      errors.promoCode = 'This code is invalid';
    }
  }

  if (!values.hearAboutUs) {
    errors.hearAboutUs = 'Required';
  }

  if (hearAboutUsHelper.isSalesRepRequired(values.hearAboutUs) && !values.hearAboutUsSalesRep) {
    errors.hearAboutUsSalesRep = 'Required';
  }

  if (hearAboutUsHelper.isOtherRequired(values.hearAboutUs)) {
    if (!values.hearAboutUsOther || (values.hearAboutUsOther && values.hearAboutUsOther.replace(/ /g, '') === '')) {
      errors.hearAboutUsOther = 'Required';
    } else if (values.hearAboutUsOther.length < 3 || values.hearAboutUsOther.length > 30) {
      errors.hearAboutUsOther = 'Minimum 3 characters and no more than 30';
    }
  }

  if (props.credentialsValidationVersion === 'V1') {
    if (!values.password) {
      errors.password = 'Required';
    } else if (!(/^(?!^[0-9]*$)(?=.*[a-z])(?=.*\d)[a-zA-Z\d!@#$%^&*?_~-]{7,23}$/i).test(values.password)) {
      errors.password = 'Not a valid password';
    } else if (values.password.length > 0 && values.password === values.username) {
      errors.password = 'Username and Password cannot be the same.';
    }

    if (!props.missingContactData) {
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
      } else if (!(/^(?!^[0-9]*$)(?=.*[a-z])(?=.*\d)[a-zA-Z\d!@#$%^&*?_~-]{7,23}$/i).test(values.confirmPassword)) {
        errors.confirmPassword = 'Not a valid password';
      } else if (values.confirmPassword.length > 0 && values.confirmPassword === values.username) {
        errors.confirmPassword = 'Username and Password cannot be the same.';
      } else if (values.password && !errors.password && values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Password and confirmation password do not match.';
      }
      if (!values.username) {
        errors.username = 'Required';
      } else if (!(/^[a-zA-Z0-9]{5,30}$/i).test(values.username)) {
        errors.username = 'Not a valid username';
      }
    }
  }

  if (props.credentialsValidationVersion === 'V2') {
    if (!values.password) {
      errors.password = 'Required';
    } else {
      const passwordValidationErrors = validatePassword(credentials);
      if (passwordValidationErrors && passwordValidationErrors.length > 0) {
        errors.password = passwordValidationErrors;
      }
    }

    if (!props.missingContactData) {
      const confirmPasswordErrors = {};
      if (!values.confirmPassword) {
        confirmPasswordErrors.error = 'Required';
      } else {
        credentials.password = values.confirmPassword;
        const passwordValidationErrorsConfirm = validatePassword(credentials);
        if (passwordValidationErrorsConfirm && passwordValidationErrorsConfirm.length > 0) {
          confirmPasswordErrors.error = 'Not a valid password';
        }
      }

      if (values.password && !errors.password && values.confirmPassword !== values.password) {
        confirmPasswordErrors.error = 'Password and confirmation password do not match.';

        if (values.confirmPassword) {
          const confirmPasswordLength = values.confirmPassword.length;
          const passwordLength = values.password.length;
          const passwordSubstring = values.password.substring(0, confirmPasswordLength);
          confirmPasswordErrors.confirmPasswordProgressPercentage = 100 * (confirmPasswordLength / passwordLength);
          confirmPasswordErrors.confirmPasswordIsValidSoFar = values.confirmPassword === passwordSubstring;
        }
      }

      if (confirmPasswordErrors.error) {
        errors.confirmPassword = confirmPasswordErrors;
      } else if (errors.password && values.confirmPassword) {
        confirmPasswordErrors.confirmPasswordProgressPercentage = 0;
        errors.confirmPassword = confirmPasswordErrors;
      }

      if (!values.username) {
        errors.username = 'Required';
      } else if (!(/^[a-zA-Z0-9_-]{5,30}$/i).test(values.username) || onlyNumbersPattern.test(values.username) ||
        monexUsernamePattern.test(values.username)) {
        errors.username = 'Not a valid username';
      }
    }
  }

  return errors;
};

export default validate;
